// this is an autogenerated file from DesignSystem/PatientOverview/Content/Comms/Actions/Content/ContactMethods/mutationInApp.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PatientOverview_Content_Comms_Actions_Content_ContactMethods($person_id: numeric!) {
  thread: comms_threads_in_app_create(person_id: $person_id) {
    id
  }
}

`