// this is an autogenerated file from DesignSystem/PatientOverview/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PatientOverview($patient_id: uuid!) {
  vaxiom_patients(where: { _id: { _eq: $patient_id } }) {
    id
    _id
    person {
      id
      _id
      profile_pic_url
      gender
      birth_date
      greeting
      first_name
      last_name
      public_user {
        id
        hub_access_status
      }
    }
  }
}

`