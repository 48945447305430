import { useDataValue } from 'Simple/Data.js'
import useStableValue from 'Logic/useStableValue.js'

export default function useDataConfiguration(props) {
  let primary_location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })
  let search_term = useDataValue({
    context: 'existing_patient',
    path: 'search_term',
    viewPath: props.viewPath,
  })
  let search_term_stable = useStableValue(`%${search_term}%`)

  return {
    variables: {
      search_term: search_term_stable || '',
      primary_location_id,
    },
    pause: search_term_stable.length < 3,
  }
}
