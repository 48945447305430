import View from './view.js'
import { useEffect, useMemo, useRef } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data'
import useIsMedia from 'Simple/hooks/useIsMedia.js'

export default function Logic(props) {
  let innerRef = useRef()
  let containerRef = useRef()

  let isFloating = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'sidebar.isFloating',
  })

  let isFloatingAndVisible = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'sidebar.isFloatingAndVisible',
  })

  let sidebarTopSpace = useMemo(() => {
    if (innerRef.current) {
      return innerRef.current.offsetHeight
    } else return 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFloatingAndVisible, isFloating])

  let sidebarHeight = useMemo(() => {
    return `calc(100vh - ${sidebarTopSpace}px)`
  }, [sidebarTopSpace])

  let sidebar_toggles = useDataValue({
    context: 'global',
    viewPath: props.viewPath,
    path: 'sidebar_toggles',
  })

  let screen_width = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'screen_width',
  })

  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let isMedia = useIsMedia()

  useEffect(() => {
    let handleResize = () => {
      change(next => {
        next.screen_width = window.innerWidth
      })
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    change(next => {
      if (isMedia.mobile || isMedia.tablet) {
        next.is_full_width = false
      } else {
        let numberOfSidebarsVisible =
          +sidebar_toggles.is_agenda_sidebar_open +
          +sidebar_toggles.is_chairs_sidebar_open +
          +!isFloating

        // Adjust remaining width based on the sidebar visibility
        next.is_full_width =
          // All three sidebars (calendar, agenda and chairs) are visible
          (screen_width >= 1920 && numberOfSidebarsVisible === 3) ||
          // Any two of the sidebars are open
          (screen_width >= 1680 && numberOfSidebarsVisible === 2) ||
          // One sidebar is open
          (screen_width >= 1440 && numberOfSidebarsVisible === 1) ||
          // Neither of the sidebars are open
          (screen_width >= 1280 && numberOfSidebarsVisible === 0)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sidebar_toggles.is_agenda_sidebar_open,
    sidebar_toggles.is_chairs_sidebar_open,
    isFloating,
    isMedia.mobile,
    isMedia.tablet,
    screen_width,
  ])

  return (
    <View
      innerRef={innerRef}
      containerRef={containerRef}
      sidebarTopSpace={sidebarTopSpace}
      sidebarHeight={sidebarHeight}
      {...props}
    />
  )
}
