import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    let scheduled =
      data?.scheduled?.map(item => ({
        ...item,
        booking: item.booking?.[0],
      })) || []
    let unscheduled = data?.unscheduled || []

    return map(
      groupBy([...scheduled, ...unscheduled], 'tx.id'),
      appointments => {
        let {
          tx: { tx_card },
        } = appointments[0]

        return {
          tx_card,
          appointments,
        }
      }
    )
  }, [data])
}
