import { useMemo } from 'react'

function orderAppointments(appointments) {
  if (!appointments?.length) {
    return []
  }

  let ordered = []

  let lastAppointment = appointments?.find(item => !item.next_appointment_id)

  while (lastAppointment) {
    let id = lastAppointment?.id
    ordered.unshift({
      id,
      name: lastAppointment.type.display_name,
      color_id: lastAppointment.type.display_color_id,
      duration: lastAppointment.duration,
      interval_to_next: lastAppointment.interval_to_next,
    })

    lastAppointment = appointments.find(item => item.next_appointment_id === id)
  }

  return ordered
}

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.length) return data

    return {
      id: data[0].id,
      name: data[0].name,
      appointments: orderAppointments(data[0].tx_plan_template_appointments),
    }
  }, [data])
}
