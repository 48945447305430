// this is an autogenerated file from DesignSystem/ManualAutomations/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__DesignSystem_ManualAutomations($user_id: uuid!) {
  events_manual_event_action_executions(args: { _user_id: $user_id }) {
    id
  }
}

`