// this is an autogenerated file from DesignSystem/ManualAutomations/Content/Content/ActiveAction/TaskInsert/Assignee/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ManualAutomations_Content_Content_ActiveAction_TaskInsert_Assignee($organization_id: numeric!) {
  vaxiom_sys_users(
    where: {
      person: {
        employment_contracts: {
          deleted: { _eq: false }
          employment_end_date: { _is_null: true }
          employee_resources: {
            resource: { organization_id: { _eq: $organization_id } }
          }
        }
      }
      _or: [
        { account_expiration_date: { _is_null: true } }
        { account_expiration_date: { _gte: "now()" } }
      ]
      disabled: { _eq: false }
      locked: { _eq: false }
    }
  ) {
    id
    person {
      id
      first_name
      last_name
    }
  }
}

`