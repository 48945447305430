// this is an autogenerated file from DesignSystem/EditAutomation/EventActions/Content/EventAction/Content/Content/TaskInsert/TaskBasket/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_EditAutomation_EventActions_Content_EventAction_Content_Content_TaskInsert_TaskBasket{
  vaxiom_task_baskets {
    id
    text: title
  }
}

`