import { useDataChange, useDataValue } from 'Simple/Data'
import { useSetFlowTo } from 'Simple/Flow'
import { useNotifications } from 'Logic/Notifications'

export default function useOnAcceptTreatmentAction(props) {
  let changeSelectedTxPlanning = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.treatment.treatment_plans',
  })
  let selectedTxPlanning = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.treatment.treatment_plans',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  return function onAcceptTreatmentAction() {}
}
