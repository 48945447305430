export function getWidth(plans) {
  switch (plans.length) {
    case 2:
      return '50%'
    case 3:
      return '33%'
    case 4:
      return '25%'
    default:
      return '840px'
  }
}
