import React, { useEffect, useMemo, useState } from 'react'
import { useFloating, offset, flip, shift } from '@floating-ui/react-dom'

export default function TysiaCollapsibleFloatingPortal(props) {
  let { containerRef, keepVisible } = props
  let [isVisible, setIsVisible] = useState(false)
  let [mousePos, setMousePos] = useState({ mouseX: 0, mouseY: 0 })
  let {
    x,
    y,
    strategy,
    refs: { floating: sidebarRef, setFloating },
    update,
  } = useFloating({
    open: props.isFloating && isVisible,
    placement: props.placement,
    middleware: [offset(0), flip(), shift()],
  })

  /**
   *
   * @param {DOMRect} rect
   * @param {{mouseX: number, mouseY: number}} mousePos
   * @param {number} border
   */
  function isWithinBound(rect, { mouseX, mouseY }, border = 0) {
    return (
      mouseX >= rect.left - border &&
      mouseX <= rect.right + border &&
      mouseY >= rect.top - border &&
      mouseY <= rect.bottom + border
    )
  }

  /** @type {(ev: MouseEvent) => void} */
  function handleMouseMove(ev) {
    setMousePos({ mouseX: ev.clientX, mouseY: ev.clientY })
  }

  useEffect(() => {
    // Add mousemove event listener to the document
    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  useEffect(() => {
    if (keepVisible) {
      setIsVisible(true)
      update()

      return
    }

    if (!containerRef.current || !sidebarRef.current) return

    let edgeThreshold = props.edgeThreshold ?? 20

    /** @type {DOMRect} */
    let rect = containerRef.current.getBoundingClientRect()
    let { mouseX, mouseY } = mousePos

    // Check if mouse is within the container
    let isMouseWithinContainer = isWithinBound(
      rect,
      { mouseX, mouseY },
      edgeThreshold
    )

    if (!isMouseWithinContainer) {
      // Mouse is outside the container, hide the sidebar if visible
      if (isVisible) {
        setIsVisible(false)
        update()
      }
      return // Do not proceed further
    }

    let floatingContainerWidth =
      isVisible && sidebarRef.current ? sidebarRef.current.offsetWidth : 0
    let floatingContainerHeight =
      isVisible && sidebarRef.current ? sidebarRef.current.offsetHeight : 0

    let shouldShow = false
    let shouldHide = false

    switch (props.placement) {
      case 'top':
      case 'top-start':
      case 'top-end':
        // Check if mouse is near the top edge of the container
        shouldShow = mouseY >= rect.top && mouseY <= rect.top + edgeThreshold
        shouldHide = mouseY > rect.top + floatingContainerHeight && isVisible
        break

      case 'right':
      case 'right-start':
      case 'right-end':
        // Check if mouse is near the right edge of the container
        shouldShow =
          mouseX >= rect.right - edgeThreshold && mouseX <= rect.right
        shouldHide = mouseX < rect.right - floatingContainerWidth && isVisible
        break

      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        // Check if mouse is near the bottom edge of the container
        shouldShow =
          mouseY >= rect.bottom - edgeThreshold && mouseY <= rect.bottom
        shouldHide = mouseY < rect.bottom - floatingContainerHeight && isVisible
        break

      case 'left':
      case 'left-start':
      case 'left-end':
      default:
        // Check if mouse is near the left edge of the container
        shouldShow = mouseX >= rect.left && mouseX <= rect.left + edgeThreshold
        shouldHide = mouseX > rect.left + floatingContainerWidth && isVisible
        break
    }

    if (shouldShow && !isVisible) {
      setIsVisible(true)
      update()
    } else if (shouldHide && isVisible) {
      setIsVisible(false)
      update()
    }
  }, [mousePos, isVisible, keepVisible, props.edgeThreshold, props.placement])

  useEffect(() => {
    // This is to handle the situation when the sidebar is first
    // set into the floating state, it should go out of view.
    setIsVisible(!props.isFloating)
    update()
  }, [props.isFloating])

  let getTransform = useMemo(() => {
    switch (props.placement) {
      case 'top':
      case 'top-start':
      case 'top-end':
        return isVisible ? 'translateY(0)' : 'translateY(-100%)'
      case 'right':
      case 'right-start':
      case 'right-end':
        return isVisible ? 'translateX(0)' : 'translateX(100%)'
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        return isVisible ? 'translateY(0)' : 'translateY(100%)'
      case 'left':
      case 'left-start':
      case 'left-end':
      default:
        return isVisible ? 'translateX(0)' : 'translateX(-100%)'
    }
  }, [props.placement, isVisible])

  let size = useMemo(() => {
    switch (props.placement) {
      case 'top':
      case 'top-start':
      case 'top-end':
      case 'bottom':
      case 'bottom-start':
      case 'bottom-end':
        return {
          width: props.width ?? '100%',
          height: props.height,
          isHorizontal: true,
        }
      case 'right':
      case 'right-start':
      case 'right-end':
      case 'left':
      case 'left-start':
      case 'left-end':
      default:
        return {
          width: props.width,
          height: props.height ?? '100%',
          isHorizontal: false,
        }
    }
  }, [props.placement, props.width, props.height])

  let alignment = useMemo(() => {
    switch (props.placement) {
      case 'left':
      case 'left-start':
      case 'left-end':
      case 'top':
      case 'top-start':
      case 'top-end':
        return { top: y ?? 0, left: x ?? 0 }

      case 'right':
      case 'right-start':
      case 'right-end':
      case 'bottom':
      case 'bottom-end':
      case 'bottom-start':
      default:
        return { bottom: y ?? 0, right: x ?? 0 }
    }
  }, [props.placement, x, y])

  return (
    <div
      ref={setFloating}
      style={
        props.isFloating
          ? {
              position: strategy,
              width: size.width,
              height: size.height,
              backgroundColor: 'white',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              pointerEvents: isVisible ? 'auto' : 'none',
              transition: `transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1.0), opacity ${
                isVisible ? '0.4s' : '1.5s'
              } ${
                isVisible
                  ? 'cubic-bezier(0, 0, 0.2, 1)'
                  : 'cubic-bezier(0.4, 0, 0, 0.6)'
              }`,
              transform: getTransform,
              opacity: isVisible ? 1 : 0,
              zIndex: props.zIndex ?? 5,
              ...alignment,
              ...props,
            }
          : {}
      }
    >
      {typeof props.children === 'function' ? props.children() : props.children}
    </div>
  )
}
