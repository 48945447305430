import { DialogOverlay, DialogContent } from '@reach/dialog'

export default function Modal(props) {
  return (
    <DialogOverlay onDismiss={props.onDismiss}>
      <DialogContent
        style={{
          borderRadius: 4,
          width: '90%',
        }}
      >
        {props.children}
      </DialogContent>
    </DialogOverlay>
  )
}
