import { useDataSubmit } from 'Simple/Data'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  return function onSubmit(value) {
    submit({ type: 'copy', locations: value })
    if (typeof props.closePopover === 'function') props.closePopover()
  }
}
