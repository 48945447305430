import { useDataChange } from 'Simple/Data'
import {
  NOTE_TEMPLATE_TAGS,
  NOTE_TEMPLATE_EDITOR_VARIANTS,
} from 'Data/constants.js'
import { stripHtml } from 'Data/format.js'

export function tagsToTag(tags) {
  return tags[0] || null
}

export function useOnChange(props) {
  let change = useDataChange({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  return function onChange(id) {
    change(next => {
      next.note_template.tags = [id]

      if (
        NOTE_TEMPLATE_TAGS[id]?.editorVariant ===
        NOTE_TEMPLATE_EDITOR_VARIANTS.plaintext
      ) {
        next.note_template.content = stripHtml(next.note_template.content)
      }
    })
  }
}
