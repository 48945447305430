import { numberMoney } from 'Data/format.js'
import {
  getPrimaryInsuranceEstimatedReimbursement,
  getSecondaryInsuranceEstimatedReimbursement,
} from 'Data/payment-plan.js'

export function getValue(payment_plan, treatment_plan) {
  if (payment_plan.insurances.length === 0) return numberMoney(0)

  let primary_insurance = payment_plan.insurances.find(
    insurance => insurance.is_primary
  )
  let secondary_insurance = payment_plan.insurances.find(
    insurance => !insurance.is_primary
  )
  let primary_insurance_estimated_reimbursement =
    primary_insurance && !primary_insurance.is_placeholder
      ? getPrimaryInsuranceEstimatedReimbursement(
          primary_insurance,
          payment_plan,
          treatment_plan
        ).estimated_reimbursement_amount
      : 0
  let secondary_insurance_estimated_reimbursement =
    secondary_insurance && !secondary_insurance.is_placeholder
      ? getSecondaryInsuranceEstimatedReimbursement(
          primary_insurance,
          secondary_insurance,
          payment_plan,
          treatment_plan
        ).estimated_reimbursement_amount
      : 0
  return numberMoney(
    primary_insurance_estimated_reimbursement +
      secondary_insurance_estimated_reimbursement
  )
}
