import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let items = useDataValue({
    context: 'list_dialog',
    path: 'items',
    viewPath: props.viewPath,
  })
  let onFilter = useDataValue({
    context: 'list_dialog',
    path: 'onFilter',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'list_dialog',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value === '') {
      change(next => {
        next.search_term = ''
        next.filtered_items = items
      })
    }

    change(next => {
      next.search_term = value
      next.filtered_items = onFilter(value, items)
    })
  }
}
