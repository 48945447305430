import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getInsuranceTreatmentFee,
  getPrimaryInsuranceEstimatedReimbursement,
  getSecondaryInsuranceEstimatedReimbursement,
} from 'Data/payment-plan.js'

import View from './view.js'

export default function Logic(props) {
  let payment_plan = useDataValue({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'insurance',
    viewPath: props.viewPath,
  })
  let insurance = useDataValue({
    context: 'insurance',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let event = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
  })
  let setEvent = useDataChange({
    context: 'event',
    viewPath: props.viewPath,
  })

  // it is the treatment fee used as the base for the calculation of insurance's reimbursement amount
  let treatment_fee = getInsuranceTreatmentFee(payment_plan)

  useEffect(() => {
    if (insurance.is_placeholder) return

    let primary_insurance = payment_plan.insurances.find(
      insurance => insurance.is_primary
    )
    let { estimated_reimbursement_amount } = insurance.is_primary
      ? getPrimaryInsuranceEstimatedReimbursement(
          insurance,
          payment_plan,
          treatment_plan
        )
      : getSecondaryInsuranceEstimatedReimbursement(
          primary_insurance,
          insurance,
          payment_plan,
          treatment_plan
        )
    change(next => {
      next.estimated_reimbursement_amount = estimated_reimbursement_amount
    })
  }, [treatment_fee]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore insurance, payment_plan, treatment_plan

  useEffect(() => {
    // primary insurance removed, so marking the currently secondary insurance as primary
    // and re-calculating the estimated reimbursement amount
    if (event?.type !== 'insurance_removed' || insurance.is_primary) {
      return
    }

    let { estimated_reimbursement_amount } =
      getPrimaryInsuranceEstimatedReimbursement(
        insurance,
        payment_plan,
        treatment_plan
      )
    change(next => {
      next.estimated_reimbursement_amount = estimated_reimbursement_amount
      next.is_primary = true
    })
    setEvent(null)
  }, [event]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore current_index, insurance, payment_plan, setEstimatedReimbursementAmount, setEvent, treatment_plan

  return <View {...props} />
}
