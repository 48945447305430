import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.length) return data

    return data.map(item => ({
      ...item,
      id: item.appointment_type._id,
      template_id: item.id,
    }))
  }, [data])
}
