import React, { useRef } from 'react'
import View from './view.js'
import useOnClickOutside from 'use-onclickoutside'
import { normalizePath, useSetFlowTo, useFlowValue } from 'Simple/Flow.js'
import useIsHovered from 'Simple/hooks/useIsHovered.js'

export default function Logic(props) {
  let buttonRef = useRef()
  let contentRef = useRef()
  let flow = useFlowValue(props.viewPath)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
  })

  useOnClickOutside(contentRef, event => {
    console.log('click outside', event)
    if (
      event.target === buttonRef.current ||
      buttonRef.current?.contains(event.target) ||
      hasReachPortalParent(event.target) ||
      flow === 'No'
    ) {
      return
    }

    event.stopPropagation()
    setFlowTo(normalizePath(props.viewPath, 'No'))
  })

  return (
    <View
      {...props}
      buttonRef={buttonRef}
      contentRef={contentRef}
      onClick={onClick}
      selected={flow === 'Content'}
      hovered={hovered}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
    />
  )

  function onClick(event) {
    if (flow === 'Content') {
      setFlowTo(normalizePath(props.viewPath, 'No'))
    } else {
      setFlowTo(normalizePath(props.viewPath, 'Content'))
    }
  }
}

function hasReachPortalParent(element) {
  // Traverse up the DOM tree until we reach the root
  while (element.parentNode) {
    element = element.parentNode

    // Check if the parent node has the tagName 'REACH-PORTAL'
    if (element.tagName && element.tagName.toLowerCase() === 'reach-portal') {
      return true
    }
  }
  return false
}
