import Arrow from './arrow.svg?url'
import './styles.css'
import React, { useEffect, useRef } from 'react'

export default function TysiaSelect(props) {
  let setDefault = useRef(false)

  function getId(item) {
    return props.id ? item[props.id] : item.id ?? item._id
  }

  useEffect(() => {
    if (
      props.allowEmptyChoice ||
      props.value ||
      typeof props.onChange !== 'function' ||
      setDefault.current
    )
      return

    let id = getId(props.from[0])
    if (id) {
      setDefault.current = true
      props.onChange(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allowEmptyChoice, props.value, props.from, props.onChange])

  return (
    <select
      tabIndex={props.tabIndex}
      disabled={props.disabled}
      onChange={event => {
        if (props.keepValueUnchanged) {
          props.onChange(event.target.value)
        } else {
          let value = event.target.value
          let maybeNumber = value !== '' ? Number(value) : null
          props.onChange(isNaN(maybeNumber) ? value : maybeNumber)
        }
      }}
      value={props.value || ''}
      className={`views-block tysia-select-field`}
      placeholder={props.placeholder}
      style={{
        '--tsf-bi': props.disabled ? '' : `url(${Arrow})`,
        '--tsf-pb': props.size === 'small' ? '2px' : '4px',
        '--tsf-pt': props.size === 'small' ? '2px' : '4px',
        '--tsf-c': props.color || '#52606D',
      }}
    >
      {props.allowEmptyChoice ? (
        <option key="none" value="">
          {props.placeholder}
        </option>
      ) : null}
      {props.from
        ? props.from.map(item => {
            let id = getId(item)
            return (
              <option key={id} value={id}>
                {item[props.field]}
              </option>
            )
          })
        : []}
    </select>
  )
}
