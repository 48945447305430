import { useDataSubmit } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'settings',
    viewPath: props.viewPath,
  })

  return function onSubmit(selected_appointment_types) {
    submit({
      key: 'selected_appointment_templates',
      value: selected_appointment_types,
    })
    if (typeof props.closePopover === 'function') props.closePopover()
  }
}

export function useOnFilter(props) {
  return function onFilter(value, items) {
    let valueRegex = new RegExp(value, 'i')
    return items.filter(item => valueRegex.test(item.full_name))
  }
}
