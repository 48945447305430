import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let manual_action_execution_ids = useDataValue({
    viewPath: props.viewPath,
    context: 'manual_action_execution_ids',
  })

  return {
    variables: {
      ids: manual_action_execution_ids.map(item => item.id),
    },
    pause: false,
  }
}
