// this is an autogenerated file from DesignSystem/ManualAutomations/Content/Content/ActiveAction/CheckRequirements/Requirements/Requirement/Skipped/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ManualAutomations_Content_Content_ActiveAction_CheckRequirements_Requirements_Requirement_Skipped_Content($id: numeric!) {
  vaxiom_task_baskets_by_pk(id: $id) {
    id
    title
  }
}

`