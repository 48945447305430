import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })
  let data = useDataValue({
    context: 'template_select',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    let selected = value ? data.find(item => item.id === value) : null
    change(next => {
      next.template_id = selected?.template_id || null
      next.appointment_type_id = selected?.id || null
      next.date = null
    })
  }
}
