import { useDataChange } from 'Simple/Data.js'

export function useOnChangeActionType(props) {
  let change = useDataChange({
    context: 'event_action',
    viewPath: props.viewPath,
  })
  let changeExpanded = useDataChange({
    context: 'expand',
    path: 'is_expanded',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.type = value
      next.data = getActionData(value)
    })
    changeExpanded(true)
  }
}

function getActionData(action_type) {
  switch (action_type) {
    case 'task_insert':
      return {
        title: null,
        description: null,
        task_basket_id: null,
        document_template_id: null,
      }
    case 'status_update':
      return {
        is_subset: false,
        subset_statuses: [],
        has_default_status: false,
        default_status: null,
      }
    case 'http':
      return {
        req: {
          url: '',
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
          body: null,
        },
      }
    case 'check_requirements':
      return {
        requirements: [],
      }
    default:
      return null
  }
}
