import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataSubmit, useDataChange, useDataValue } from 'Simple/Data.js'
import { textToNumber, numberMoney } from 'Simple/Data/format.js'
import { PAYMENTS_PAYMENT_TYPES } from 'Data/common-constants.js'

import mutationCash from './mutation_cash.graphql.js'
import mutationCheck from './mutation_check.graphql.js'
import mutationCreditCard from './mutation_credit_card.graphql.js'
import mutationCreditCardManual from './mutation_credit_card_manual.graphql.js'
import mutationOther from './mutation_other.graphql.js'
import mutationBankAccount from './mutation_bank_account.graphql.js'
import mutationPersonPaymentAccount from './mutation_person_payment_account.graphql.js'
import queryPatientDueNow from './query_patient_due_now.graphql.js'
import queryPaymentReceiptDownload from './query_payment_receipt_download.graphql.js'
import mutationPaymentReceiptSendEmail from './mutation_payment_receipt_send_email.graphql.js'
import { validate } from './helpers.js'
import formatISO from 'date-fns/formatISO'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionFormSubmit = useDataOnActionFormSubmit(props)
  let onDueAmountSubmit = useDataOnDueAmountSubmit(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      // temp form blocking while querying due amount
      case 'due_amount_submit': {
        return onDueAmountSubmit({ value, args, change })
      }
      case 'form_submit': {
        return onActionFormSubmit({ value, args, change })
      }
      default: {
      }
    }
  }
}

function useDataOnDueAmountSubmit(props) {
  let client = useClient()

  let patient_uuid = useDataValue({
    context: 'message',
    path: 'params.patient_uuid',
    viewPath: props.viewPath,
  })

  return async function onAction({ value, args, change }) {
    // dont update amount, if we are working on receivable
    if (value.receivable_id) return

    let mutationResponse = await client
      .query(queryPatientDueNow, {
        patient_id: patient_uuid,
        payment_account_id: args.payment_account_id,
      })
      .toPromise()

    if (mutationResponse.error) {
      change(next => {
        next.amount = numberMoney(0)
      })
      return
    }

    change(next => {
      next.amount = numberMoney(
        mutationResponse.data.financial_patient_due_amount?.[0]?.due_now ?? 0
      )
    })
  }
}

function useHandleReceipt() {
  let [, notify] = useNotifications()
  let client = useClient()
  let [, executeMutationPaymentReceiptSendEmail] = useMutation(
    mutationPaymentReceiptSendEmail
  )

  /**
   * @param {{ payment_id: number, person_id: number, print: boolean, email?: string }} param0
   */
  return async function handleReceipt({ payment_id, person_id, print, email }) {
    // eslint-disable-next-line compat/compat
    await Promise.allSettled(
      [print && printReceipt(), email && sendReceiptViaEmail()].filter(Boolean)
    )

    async function printReceipt() {
      notify(notifySuccess('Preparing PDF'))

      try {
        let mutationResponse = await client
          .query(queryPaymentReceiptDownload, {
            payment_id,
            person_id,
          })
          .toPromise()

        if (mutationResponse.error) {
          notify(notifyError('Failed to prepare PDF'))
          return
        }

        let url = mutationResponse.data.vaxiom_payment_receipt_download.url

        if (!url) {
          notify(notifyError('Unable to generate PDF'))
          return
        }

        let pdfWindow = window.open(url, '_blank')
        pdfWindow.onload = () => pdfWindow.print()
      } catch {
        //
      }
    }

    async function sendReceiptViaEmail() {
      notify(notifySuccess('Sending receipt via email'))

      try {
        let mutationResponse = await executeMutationPaymentReceiptSendEmail({
          payment_id,
          person_id,
          email,
        })

        if (mutationResponse.error) {
          notify(notifyError('Failed to send receipt via email'))
        }
      } catch {
        //
      }
    }
  }
}

function useDataOnActionFormSubmit(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let paymentSubmit = useDataSubmit({
    context: 'payment',
    viewPath: props.viewPath,
  })
  let refreshEmbeddedSection = useDataChange({
    context: 'global',
    path: 'refresh_embedded_section',
    viewPath: props.viewPath,
  })
  let parent_organization_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company.id',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })
  let person_id = useDataValue({
    context: 'post_payment_info',
    path: 'patient.person.id',
    viewPath: props.viewPath,
  })
  let patient_uuid = useDataValue({
    context: 'message',
    path: 'params.patient_uuid',
    viewPath: props.viewPath,
  })
  let [, executeMutationCashPayment] = useMutation(mutationCash)
  let [, executeMutationOtherPayment] = useMutation(mutationOther)
  let [, executeMutationCreditCardPayment] = useMutation(mutationCreditCard)
  let [, executeMutationCreditCardManualPayment] = useMutation(
    mutationCreditCardManual
  )
  let [, executeMutationBankAccountPayment] = useMutation(mutationBankAccount)
  let [, executeMutationCheckPayment] = useMutation(mutationCheck)
  let [, executeMutationPersonPaymentAccount] = useMutation(
    mutationPersonPaymentAccount
  )
  let handleReceipt = useHandleReceipt()

  return async function onAction({ args, value, change }) {
    let validation = validate(value)

    if (validation.isInvalid) {
      return failure(new Error(validation.errors[0]))
    }

    let baseVariables = {
      amount: textToNumber(value.amount),
      reference_number: value.reference_number,
      notes: value.notes,
      location_id,
      payment_type_id: value.payment_method.data.payment_type_id,
      patient_id: patient_uuid,
      payment_account_id: value.payment_account.data.payment_account_id,
      auto_apply_to_unpaid_receivables: value.auto_apply_to_unpaid_receivables,
      insured_id: value.payment_account.data.insured_id,
      receivable_id: value.receivable_id,
    }

    // we have to create new payment account
    if (!baseVariables.payment_account_id) {
      try {
        // this will trigger refetch on AccountSelect
        let mutationResponse = await executeMutationPersonPaymentAccount({
          payer_person_id: value.payment_account.data.payer_person.id,
          parent_organization_id,
        })

        if (mutationResponse.error) throw mutationResponse.error

        let { payment_account: new_payment_account } =
          mutationResponse.data.insert_vaxiom_person_payment_accounts_one

        // at this point, new payment account has been created, and AccountSelect data is being refetched
        // setting selected_in AccountSelect correctly position this new payment account into `Existing` and select it in dropdown
        // this is only necessary for case when the XyzPayment mutation fails, its kind of a recovery mechanism for this form
        change(next => {
          next.payment_account.selected_id = new_payment_account.id
        })

        // this is set for current submission happy case scenario, so that submit does not need to be clicked again
        // after creating payment account
        baseVariables.payment_account_id = new_payment_account.id
      } catch (error) {
        return failure(error)
      }
    }

    switch (
      args.paymentType ||
      value.payment_method.data.payments_payment_type
    ) {
      case PAYMENTS_PAYMENT_TYPES.CashPayment: {
        try {
          let mutationResponse = await executeMutationCashPayment(baseVariables)

          if (mutationResponse.error) throw mutationResponse.error

          return success(
            mutationResponse.data.payments_post_payment_cash.payment_id
          )
        } catch (error) {
          return failure(error)
        }
      }
      case PAYMENTS_PAYMENT_TYPES.CheckPayment: {
        try {
          let fallback_payer_person = value.payment_account.data.payer_person
          let mutationResponse = await executeMutationCheckPayment({
            ...baseVariables,
            // prettier-ignore
            payer_name: value.check.payer_name || `${fallback_payer_person.first_name} ${fallback_payer_person.first_name}`,
            check_number: value.check.number,
          })

          if (mutationResponse.error) throw mutationResponse.error

          return success(
            mutationResponse.data.payments_post_payment_check.payment_id
          )
        } catch (error) {
          return failure(error)
        }
      }
      case PAYMENTS_PAYMENT_TYPES.OtherPayment: {
        try {
          let mutationResponse = await executeMutationOtherPayment(
            baseVariables
          )

          if (mutationResponse.error) throw mutationResponse.error

          return success(
            mutationResponse.data.payments_post_payment_other.payment_id
          )
        } catch (error) {
          return failure(error)
        }
      }
      case PAYMENTS_PAYMENT_TYPES.BankAccountPayment: {
        try {
          let token = await paymentSubmit({ type: 'tokenize' })

          // true means invalid in this context
          if (token === true || !token) return true

          let mutationResponse = await executeMutationBankAccountPayment({
            ...baseVariables,
            token,
          })

          if (mutationResponse.error) {
            if (
              mutationResponse.error.graphQLErrors?.[0].extensions.code ===
              'payments/payment-failed'
            ) {
              throw new Error(
                `${mutationResponse.error.graphQLErrors[0].message}: Payment failed to process, please try another payment method`
              )
            } else {
              throw new Error(
                `There was an issue taking the payment. Please try again. ${mutationResponse.error.graphQLErrors[0].message}`
              )
            }
          }

          return success(
            mutationResponse.data.payments_post_payment_bank_account.payment_id
          )
        } catch (error) {
          return failure(error)
        }
      }
      case PAYMENTS_PAYMENT_TYPES.CreditCardPayment: {
        try {
          let token = await paymentSubmit({ type: 'tokenize' })
          // true means invalid in this context
          if (token === true || !token) return true

          let mutationResponse = await executeMutationCreditCardPayment({
            ...baseVariables,
            token,
          })

          if (mutationResponse.error) {
            if (
              mutationResponse.error.graphQLErrors?.[0].extensions.code ===
              'payments/payment-failed'
            ) {
              throw new Error(
                `Payment failed to process, please try another payment method. ${mutationResponse.error.graphQLErrors[0].message}.`
              )
            } else {
              throw new Error(
                `There was an issue taking the payment. Please try again. ${mutationResponse.error.graphQLErrors[0].message}`
              )
            }
          }

          return success(
            mutationResponse.data.payments_post_payment_credit_card.payment_id
          )
        } catch (error) {
          return failure(error)
        }
      }
      case PAYMENTS_PAYMENT_TYPES.CreditCardManualPayment: {
        try {
          let mutationResponse = await executeMutationCreditCardManualPayment({
            ...baseVariables,
            transaction_id: value.credit_card_manual.transaction_id,
            effective_date: value.credit_card_manual.effective_date,
          })

          if (mutationResponse.error) throw mutationResponse.error

          return success(
            mutationResponse.data.payments_post_payment_credit_card_manual
              .payment_id
          )
        } catch (error) {
          return failure(error)
        }
      }
      // TODO: remove once Payabli works as expected
      case PAYMENTS_PAYMENT_TYPES.PaymentPagePayment: {
        try {
          if (args.value.resultText !== 'Approved') {
            throw new Error(
              `Payment page transaction failed. ${JSON.stringify(args.value)}.`
            )
          }

          let mutationResponse = await executeMutationCreditCardManualPayment({
            ...baseVariables,
            notes: [
              baseVariables.notes,
              `payment_page_result=${JSON.stringify(args.value)}`,
            ].join('\n'),
            transaction_id: args.value.referenceId,
            effective_date: formatISO(Date.now(), { representation: 'date' }),
          })

          if (mutationResponse.error) throw mutationResponse.error

          return success(
            mutationResponse.data.payments_post_payment_credit_card_manual
              .payment_id
          )
        } catch (error) {
          return failure(error)
        }
      }
      default: {
        return failure(new Error('Something went wrong'))
      }
    }

    /**
     * @param {number} payment_id
     * @returns {boolean}
     */
    function success(payment_id) {
      notify(notifySuccess('Payment was successful'))

      // we dont need await here, notifications are handled within
      handleReceipt({
        person_id,
        payment_id,
        print: value.receipt.checkbox.print,
        email:
          value.receipt.checkbox.email && value.receipt.email
            ? value.receipt.email
            : undefined,
      })

      setFlowTo(normalizePath(props.viewPath, '../../No'))
      // TODO: imho, this is bad ux, user will loose filters, toggles in core ui etc...
      refreshEmbeddedSection(Date.now())

      return false // false means success
    }

    /**
     * @param {Error} error
     * @returns {boolean}
     */
    function failure(error) {
      notify(notifyError(error?.message ?? 'Something went wrong'))

      return true // true means fail
    }
  }
}
