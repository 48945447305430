import { useDataChange } from 'Simple/Data'
import { subMonths } from 'date-fns'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.selected.date = subMonths(next.selected.date, 1)
    })
  }
}
