import React, { useState, useEffect } from 'react'

import View from './view.js'

export default function Logic(props) {
  let [formatted, setFormatted] = useState(props.value)

  useEffect(() => {
    let result = toNumber(formatted)
    if (!isNaN(result) && result !== props.value) {
      setFormatted(props.value)
    }
  }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} value={formatted} onChange={onChange} />

  function onChange(value) {
    setFormatted(value)
    let result = toNumber(value)
    if (!isNaN(result) && result !== props.value) {
      // update parent when value is a valid number
      props.onChange && props.onChange(result)
    }
  }
}

function toNumber(value) {
  return typeof value === 'string'
    ? parseFloat(value.replace(/[^0-9.]/g, ''))
    : value
}
