import React from 'react'
import { DataProvider } from 'Simple/Data.js'

import View from './view.js'

let value = {
  id: 1,
  length_in_weeks: 60,
}

export default function Logic(props) {
  return (
    <DataProvider
      context="treatment_plan"
      value={value}
      viewPath={props.viewPath}
    >
      <DataProvider context="event" value={null} viewPath={props.viewPath}>
        <View {...props} />
      </DataProvider>
    </DataProvider>
  )
}
