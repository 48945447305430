import { useDataValue } from 'Simple/Data'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import { useMemo } from 'react'

export default function useDataConfiguration(props) {
  let location_id = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.location_id',
  })

  let date = useDataValue({
    context: 'sidebar',
    viewPath: props.viewPath,
    path: 'date',
  })

  let { first_day_of_month, last_day_of_month } = useMemo(() => {
    if (!date) return
    return {
      first_day_of_month: startOfMonth(date),
      last_day_of_month: endOfMonth(date),
    }
  }, [date])

  return {
    variables: {
      location_id,
      start_date: format(first_day_of_month, 'yyyy-MM-dd'),
      end_date: format(last_day_of_month, 'yyyy-MM-dd'),
    },
    pause: !location_id || !first_day_of_month || !last_day_of_month,
  }
}
