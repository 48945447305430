/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionSubmit = useDataOnActionSubmit(props)
  let onActionReset = useDataOnActionReset(props)

  return async function onSubmit({ value, args, originalValue, change }) {
    switch (args?.type) {
      case 'submit': {
        return onActionSubmit({ value, args, originalValue, change })
      }
      case 'reset': {
        return onActionReset({ value, args, originalValue, change })
      }
      default: {
        throw new Error(`Unsupported action ${args?.type}`)
      }
    }
  }
}

function useDataOnActionSubmit(props) {
  return async function onAction({ value, args, originalValue, change }) {
    //
  }
}

function useDataOnActionReset(props) {
  return async function onAction({ value, args, originalValue, change }) {
    let { patient_option, patient_id } = value

    change({
      ...originalValue,
      ...(args.preservePatientId ? { patient_option, patient_id } : {}),
    })
  }
}
