import React, { useState } from 'react'

import Button from './Button'
import Code from './Code'
import Link from './Link'
import Modal from './Modal'
import Text from './Text'

export default function SimpleMissingView(props) {
  let [visible, setVisible] = useState(false)

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        title={`View "${props.targetViewId}" doesn't exist inside`}
      >
        ❌ {props.targetViewId}
      </Button>

      {visible && (
        <Modal onDismiss={() => setVisible(false)}>
          <Button
            style={{
              backgroundColor: '#323232',
            }}
            onClick={() => setVisible(false)}
          >
            Back
          </Button>

          <Text style={{ marginTop: 24 }}>
            View "{props.targetViewId}" doesn't exist inside "
            {props.pointOfUseViewId}" or in "DesignSystem".
          </Text>
          <Text style={{ marginTop: 12 }}>
            It's being imported from: "{props.pointOfUseViewId}" at "
            <Link href={`vscode://file${props.pointOfUseFile}`}>
              {props.pointOfUseFile}
            </Link>
            ".
          </Text>
          <Text style={{ marginTop: 12 }}>
            Either remove the reference to the view or create a new file "
            <Link href={`vscode://file${props.targetFileAtPointOfUse}`}>
              {props.targetFileAtPointOfUse}
            </Link>
            " like:
            <Code>
              {`${props.targetViewId} View
  is together
  Text
  text ${props.targetViewId}
`}
            </Code>
          </Text>

          <Text style={{ marginTop: 12 }}>
            or in the design system "
            <Link href={`vscode://file${props.targetFileAtDesignSystem}`}>
              {props.targetFileAtDesignSystem}
            </Link>
            " like:
            <Code>
              {`${props.targetViewId} View
  Text
  text ${props.targetViewId}
`}
            </Code>
          </Text>
        </Modal>
      )}
    </>
  )
}
