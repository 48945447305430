import { useMemo } from 'react'
import { NOTE_TEMPLATE_TAGS } from 'Data/constants.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.length) return data

    return data.map(note_template => {
      let user = note_template.updated_by_user?.vaxiom_person
      return {
        ...note_template,
        tags:
          note_template.tags?.map(item => NOTE_TEMPLATE_TAGS[item].name) || [],
        updated_by: user ? [user.first_name, user.last_name].join(' ') : '-',
      }
    })
  }, [data])
}
