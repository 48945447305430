import React, { useEffect, useMemo } from 'react'
import { useTable, useExpanded, useRowSelect } from 'react-table'
import TysiaIconKeyboardArrowUpFilled from 'DesignSystem/Svgs/TysiaIconKeyboardArrowUpFilled'
import TysiaIconKeyboardArrowDownFilled from 'DesignSystem/Svgs/TysiaIconKeyboardArrowDownFilled'
import TysiaCheckboxInput from 'design-system/TysiaCheckbox/TysiaCheckboxInput'
import TysiaCheckboxText from 'design-system/TysiaCheckbox/TysiaCheckboxText'
import TysiaCheckbox from 'design-system/TysiaCheckbox'
import { useDataChange, useDataValue } from 'Simple/Data'

import formatDuration from 'date-fns/formatDuration/index.js'
import addWeeks from 'date-fns/addWeeks/index.js'
import intervalToDuration from 'date-fns/intervalToDuration/index.js'
import { convertTxPlanWeeksToMonths, dataValueFormat } from 'Data/format'

function formatLength(weeks) {
  let now = new Date()
  let futureDate = addWeeks(now, weeks)

  return formatDuration(intervalToDuration({ start: now, end: futureDate }), {
    format: ['years', 'months'],
  })
}

function TreatmentTable(props) {
  let data = useMemo(() => props.values, [])

  let value = useDataValue({
    context: 'tab',
    path: 'selected.treatment.treatment_plans.plans',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'tab',
    path: 'selected.treatment.treatment_plans.plans',
    viewPath: props.viewPath,
  })

  let columns = useMemo(
    () => [
      {
        Header: 'Treatment Name',
        accessor: 'name',
        Cell: ({ row, selectedFlatRows }) => {
          return row.canExpand ? (
            <div
              {...row.getToggleRowExpandedProps()}
              onClick={() => {}}
              style={{
                fontSize: 11,
                fontWeight: 500,
              }}
            >
              {row.values.name || row.original.type}
            </div>
          ) : (
            <TysiaCheckbox
              viewPath={`${props.viewPath}/TysiaCheckbox`}
              value={row.isSelected}
              disabled={!row.isSelected && selectedFlatRows.length >= 4}
              onChange={value => row.toggleRowSelected()}
              size="small"
            >
              {childProps => (
                <React.Fragment>
                  <TysiaCheckboxInput
                    {...childProps}
                    viewPath={`${props.viewPath}/TysiaCheckboxInput`}
                  />
                  <TysiaCheckboxText
                    {...childProps}
                    viewPath={`${props.viewPath}/TysiaCheckboxText`}
                    text={row.values.name}
                  />
                </React.Fragment>
              )}
            </TysiaCheckbox>
          )
        },
      },
      {
        Header: 'Treatment Length',
        accessor: 'length_in_weeks',
        Cell: ({ row, selectedFlatRows }) => {
          if (row.values.length_in_weeks)
            return (
              <span
                style={{
                  opacity:
                    !row.isSelected && selectedFlatRows.length >= 4 ? 0.5 : 1,
                }}
              >
                {`${convertTxPlanWeeksToMonths(
                  row.values.length_in_weeks
                )} Months`}
              </span>
            )

          return null
        },
      },
      {
        Header: 'Treatment Fee',
        accessor: 'fee',
        Cell: ({ row, selectedFlatRows }) => {
          if (row.values.fee)
            return (
              <span
                style={{
                  opacity:
                    !row.isSelected && selectedFlatRows.length >= 4 ? 0.5 : 1,
                }}
              >
                {dataValueFormat(row.values.fee, { isCurrency: true })}
              </span>
            )

          return null
        },
      },
      {
        Header: '',
        id: 'expand',
        accessor: row => {
          return 'action'
        },
        width: 20,
        Cell: ({ row }) => {
          if (row.original.type) {
            return row.isExpanded ? (
              <TysiaIconKeyboardArrowUpFilled
                fill={props.collapseIconColor}
                viewPath={`${props.viewPath}/Tysiav2ChevronUp`}
              />
            ) : (
              <TysiaIconKeyboardArrowDownFilled
                fill={props.collapseIconColor}
                viewPath={`${props.viewPath}/Tysiav2ChevronDown`}
              />
            )
          }

          return null
        },
      },
    ],
    []
  )

  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: value?.reduce(
          (acc, item) => ({
            ...acc,
            [item.row_id]: true,
          }),
          {}
        ),
      },
      getSubRows: row => row.plans || [],
    },
    useExpanded,
    useRowSelect
  )

  useEffect(() => {
    toggleAllRowsExpanded(true)
  }, [])

  useEffect(() => {
    change(
      selectedFlatRows?.length
        ? selectedFlatRows.map(({ id, original }) => ({
            id: original?.id,
            row_id: id,
          }))
        : []
    )
  }, [selectedFlatRows?.length])

  return (
    <div
      id="table-wrapper"
      style={{
        width: 'calc(100% - 32px)',
        overflowY: 'auto',
        marginLeft: props.marginLeft,
        marginRight: props.marginRight,
        marginBottom: props.marginBottom,
        alignSelf: 'center',
      }}
    >
      <table
        {...getTableProps()}
        style={{
          fontFamily: props.fontFamily,
          fontWeight: props.fontWeight,
          fontSize: props.fontSize,
          color: props.color,
          borderSpacing: 0,
          borderRadius: props.borderRadius,
          borderWidth: props.borderWidth,
          borderStyle: props.borderStyle,
          borderColor: props.borderColor,
          overflow: props.overflow,
          width: '100%',
        }}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{}}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    cursor: props.cursorHeader,
                    backgroundColor: props.backgroundColorHeader,
                    borderBottomWidth: props.borderBottomWidthHeader,
                    borderBottomStyle: props.borderBottomStyleHeader,
                    borderBottomColor: column.columns
                      ? 'transparent'
                      : props.borderBottomColorHeader,
                    borderLeftWidth: props.borderLeftWidthHeader,
                    borderLeftStyle: props.borderLeftStyleHeader,
                    borderLeftColor: props.borderLeftColorHeader,
                    height: props.heightHeader,
                    fontWeight: props.fontWeightHeader,
                    paddingLeft: props.paddingLeftHeader,
                    paddingRight: props.paddingRightHeader,
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            let isLast = i === rows.length - 1
            prepareRow(row)
            return (
              <React.Fragment key={i}>
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={
                    row.canExpand
                      ? () => row.toggleRowExpanded()
                      : () =>
                          !row.isSelected && selectedFlatRows.length >= 4
                            ? null
                            : row.toggleRowSelected()
                  }
                  style={{
                    height: props.heightRow,
                    color: props.colorRow,
                    cursor: 'pointer',
                    backgroundColor: row.isSelected
                      ? props.backgroundColorRowSelected
                      : !!props.onGetBackgroundRowColor &&
                        typeof props.onGetBackgroundRowColor === 'function'
                      ? props.onGetBackgroundRowColor(row)
                      : props.backgroundColorRow,
                  }}
                >
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          borderBottomWidth: isLast
                            ? 0
                            : props.borderBottomWidthCell,
                          borderBottomStyle: isLast
                            ? 'none'
                            : props.borderBottomStyleCell,
                          borderBottomColor: isLast
                            ? 'transparent'
                            : props.borderBottomColorCell,
                          paddingLeft: props.paddingLeftCell,
                          paddingRight: props.paddingRightCell,

                          borderRightWidth: props.rightBorder
                            ? props.borderBottomWidthCell
                            : 0,
                          borderRightStyle: props.rightBorder
                            ? props.borderBottomStyleCell
                            : 'none',
                          borderRightColor: props.rightBorder
                            ? props.borderBottomColorCell
                            : 'transparent',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

TreatmentTable.defaultProps = {
  backgroundColorHeader: '#F5F7FA',
  backgroundColorRow: 'transparent',
  backgroundColorRowSelected: '#F5F7FA',
  borderBottomWidthCell: 1,
  borderBottomWidthHeader: 1,
  borderBottomStyleCell: 'solid',
  borderBottomStyleHeader: 'solid',
  borderBottomColorCell: '#E4E7EB',
  borderBottomColorHeader: '#E4E7EB',
  borderLeftWidthHeader: 1,
  borderLeftStyleHeader: 'solid',
  borderLeftColorHeader: '#F5F7FA',
  borderColor: '#E4E7EB',
  borderRadius: 8,
  borderStyle: 'solid',
  borderWidth: 1,
  color: '#152935',
  colorRow: '#152935',
  cursorHeader: 'pointer',
  fontFamily: 'Inter',
  fontSize: 12,
  fontWeight: 400,
  fontWeightHeader: 500,
  heightHeader: 36,
  heightRow: 44,
  marginBottom: 0,
  marginLeft: 4,
  marginRight: 4,
  marginTop: 16,
  overflow: 'hidden',
  paddingLeftCell: 16,
  paddingLeftHeader: 16,
  paddingRightCell: 16,
  paddingRightHeader: 16,
  onGetBackgroundRowColor: null,
  collapseIconColor: '#9AA5B1',
  disableColor: '#E4E7EB',
}

export default function Logic(props) {
  return <TreatmentTable {...props} />
}
