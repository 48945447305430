// this is an autogenerated file from App/Account/Topbar/Options/Content/WorkflowsAction/Content/Status/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Topbar_Options_Content_WorkflowsAction_Content_Status{
  pending_executions: workflows_executions(
    where: { status: { _nin: [completed, terminated] } }
  ) {
    id
    status
  }
}

`