import { useDataChange } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onSubmit(selected_appointment_types) {
    change(next => {
      next.selected.sidebar_appointment_types = selected_appointment_types
    })
    if (typeof props.closePopover === 'function') props.closePopover()
  }
}

export function useOnFilter(props) {
  return function onFilter(value, items) {
    let valueRegex = new RegExp(value, 'i')
    return items.filter(item => valueRegex.test(item.full_name))
  }
}
