import { useDataValue, useDataChange } from 'Simple/Data.js'
import {
  getPrimaryInsuranceEstimatedReimbursement,
  getSecondaryInsuranceEstimatedReimbursement,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let payment_plan = useDataValue({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let insurance_subscriptions = useDataValue({
    context: 'insurance_subscriptions',
    viewPath: props.viewPath,
  })
  let insurance = useDataValue({
    context: 'insurance',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'insurance',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    let insurance_subscription = insurance_subscriptions.find(
      item => item.id === value
    )
    if (!insurance_subscription) return

    let primary_insurance = payment_plan.insurances.find(
      item => item.is_primary
    )

    let { estimated_reimbursement_amount } = insurance.is_primary
      ? getPrimaryInsuranceEstimatedReimbursement(
          insurance_subscription,
          payment_plan,
          treatment_plan
        )
      : getSecondaryInsuranceEstimatedReimbursement(
          primary_insurance,
          insurance_subscription,
          payment_plan,
          treatment_plan
        )

    change({
      ...insurance_subscription,
      is_placeholder: false,
      is_primary: insurance.is_primary,
      estimated_reimbursement_amount,
    })
  }
}
