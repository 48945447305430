// this is an autogenerated file from DesignSystem/PatientOverview/Content/Comms/Actions/Content/ContactMethods/mutationDiscussion.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PatientOverview_Content_Comms_Actions_Content_ContactMethods(
  $resource: comms_thread_internal_resource!
  $thread_name: String!
  $parent_company_id: uuid!
) {
  thread: comms_threads_internal_resource_create(
    resource: $resource
    thread_name: $thread_name
    parent_company_id: $parent_company_id
  ) {
    id
    status
  }
}

`