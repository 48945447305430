import { useDataChange } from 'Simple/Data.js'

export function useOnClearAll(props) {
  let changeSelected = useDataChange({
    context: 'list_dialog',
    path: 'selected',
    viewPath: props.viewPath,
  })

  return function onClearAll() {
    changeSelected([])
  }
}
