import React, { useEffect } from 'react'
import { useDataChange, useDataFormat, useDataValue } from 'Simple/Data.js'
import {
  getAdjustedTreatmentFeeAfterInsurances,
  getUpdatedPayor,
} from 'Data/payment-plan.js'

import View from './view.js'

export default function Logic(props) {
  let payment_plan = useDataValue({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let current_index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let payor = useDataValue({
    context: 'payor',
    viewPath: props.viewPath,
  })
  let other_payor_share_percentage = useDataFormat({
    context: 'payment_plan',
    viewPath: props.viewPath,
    format: payment_plan => {
      let other_payor = payment_plan.payors.find(
        (_, index) => index !== current_index
      )
      if (!other_payor) return null
      return other_payor.share.percentage
    },
  })
  let event = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payor',
    viewPath: props.viewPath,
  })
  let setEvent = useDataChange({
    context: 'event',
    viewPath: props.viewPath,
  })

  let treatment_fee = getAdjustedTreatmentFeeAfterInsurances(
    payment_plan,
    treatment_plan
  )

  useEffect(() => {
    if (typeof treatment_fee !== 'number') return

    let target_share = {
      ...payor.share,
      amount: parseFloat(
        ((treatment_fee * payor.share.percentage) / 100).toFixed(2)
      ),
    }
    // update the configuration whenever there is a change in the treatment fee
    change(getUpdatedPayor(payor, target_share))
  }, [treatment_fee]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore payor, change

  useEffect(() => {
    if (
      event?.index === current_index ||
      event?.type !== 'payor_share_updated'
    ) {
      return
    }

    let percentage = 100 - other_payor_share_percentage
    let target_share = {
      type: 'percentage',
      percentage,
      amount: parseFloat(((treatment_fee * percentage) / 100).toFixed(2)),
    }
    // update the configuration whenever there is a change of the other payor's share
    change(getUpdatedPayor(payor, target_share))

    setEvent(null)
  }, [event, other_payor_share_percentage]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore current_index, setPayorShareUpdated

  return <View {...props} />
}
