import { useDataChange, useDataValue } from 'Simple/Data'
import { addYears, subYears } from 'date-fns'

export function useOnClick(props) {
  let current_date = useDataValue({
    context: 'dropdown',
    viewPath: props.viewPath,
    path: 'date',
  })

  let change = useDataChange({
    context: 'dropdown',
    viewPath: props.viewPath,
  })

  return function onClick(value) {
    change(next => {
      next.date =
        value.target.id === 'prev'
          ? subYears(current_date, 1)
          : addYears(current_date, 1)
    })
  }
}
