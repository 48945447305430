import { useDataSubmit, useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let change = useDataChange({
    context: 'appointment_overlay',
    path: 'patient_option',
    viewPath: props.viewPath,
  })

  return () => {
    submit({ type: 'reset' })
    change('existing')
  }
}
