import { normalizePath } from 'Simple/Flow.js'
import React from 'react'
import pluralize from 'pluralize'
import makeDebug from 'Simple/debug.js'
import { DataProvider, useDataValue } from 'Simple/Data.js'
import DefaultOption from './TysiaListDialogDefaultItemOption/view.js'
import Checkbox from './TysiaListDialogCheckbox/view.js'
import { useDesignTokenValue } from 'Simple/DesignTokens.js'

let debug = makeDebug('simple/morph/view')

export default function Logic(props) {
  let viewPath = normalizePath(props.viewPath, '..').slice(0, -1)

  let list = useDataValue({
    context: 'list_dialog',
    viewPath: props.viewPath,
    path: 'filtered_items',
  })

  let dtSpaceLs = useDesignTokenValue({
    viewPath,
    path: 'space.ls',
  })
  let dtSpaceMs = useDesignTokenValue({
    viewPath,
    path: 'space.ms',
  })

  let dataContextName =
    props.itemDataContextName ||
    defaultItemDataContextName('list_dialog_filtered_items')

  let childName = props?.childName || dataContextName
  return (
    <div
      style={{
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '0%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {Array.isArray(list) &&
        list.map((item, index, list) => {
          let childViewPath = `${viewPath}/TysiaListDialog/TysiaListDialogItemOption(${
            item?.id ?? item?._id ?? index
          })`
          return (
            <ListItem
              key={item?.id ?? item?._id ?? index}
              context={dataContextName}
              item={item}
              index={index}
              list={list}
              hasItemKey={false}
              parentViewPath={viewPath}
              viewPath={childViewPath}
            >
              <div
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: '0%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingTop: dtSpaceMs,
                  paddingBottom: dtSpaceMs,
                  paddingLeft: dtSpaceLs,
                  paddingRight: dtSpaceLs,
                  gap: dtSpaceLs,
                }}
              >
                <Checkbox viewPath={`${childViewPath}/Checkbox`} />
                {typeof props.children === 'undefined' ? (
                  <DefaultOption
                    {...{
                      index,
                      item,
                      indexReverse: list.length - index,
                      isFirst: index === 0,
                      isLast: index === list.length - 1,
                      viewPath: `${childViewPath}/DefaultOption`,
                      dsViewPath: `${childViewPath}/DefaultOption`,
                    }}
                  />
                ) : typeof props.children === 'function' ? (
                  props.children({
                    index,
                    item,
                    indexReverse: list.length - index,
                    isFirst: index === 0,
                    isLast: index === list.length - 1,
                    viewPath: `${childViewPath}/${childName}`,
                    dsViewPath: `${childViewPath}/${childName}`,
                  })
                ) : (
                  props.children
                )}
              </div>
            </ListItem>
          )
        })}
    </div>
  )
}

function ListItem(props) {
  let valueItem = React.useMemo(
    () => ({
      index: props.index,
      indexReverse: props.list.length - props.index,
      isFirst: props.index === 0,
      isLast: props.index === props.list.length - 1,
    }),
    [props.index, props.list]
  )

  if (
    process.env.NODE_ENV === 'development' &&
    !props.hasItemKey &&
    !props.item.id &&
    !props.item._id
  ) {
    debug({
      type: 'simple/data',
      warning: `Missing "id" or "_id" property on item in context "${props.context}". The list's index will be used as a fallback key. Consider setting "itemKey" if the item has a custom or compound key e.g. first_name,last_name.`,
      item: props.item,
      context: props.context,
      viewPath: props.viewPath,
    })
  }
  return (
    <DataProvider
      context={props.context}
      value={props.item}
      viewPath={props.viewPath}
    >
      <DataProvider
        context={`${props.context}_item`}
        value={valueItem}
        viewPath={props.viewPath}
      >
        {props.children}
      </DataProvider>
    </DataProvider>
  )
}

function defaultItemDataContextName(data) {
  if (!data) return ''
  return pluralize.singular(data)
}
