// this is an autogenerated file from DesignSystem/ManualAutomations/Content/Content/ActiveAction/StatusUpdate/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ManualAutomations_Content_Content_ActiveAction_StatusUpdate(
  $organization_id: numeric!
  $types: [String]!
  $has_subset: Boolean!
) {
  subset: vaxiom_tx_statuses(
    where: {
      parent_company_id: { _eq: $organization_id }
      hidden: { _eq: false }
      type: { _in: $types }
    }
  ) @include(if: $has_subset) {
    id: type
    text: name
  }
  all: vaxiom_tx_statuses(
    where: {
      parent_company_id: { _eq: $organization_id }
      hidden: { _eq: false }
    }
  ) @skip(if: $has_subset) {
    id: type
    text: name
  }
}

`