import { useDataChange } from 'Simple/Data.js'

export default function useListItemDataOnChange(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onChange(next) {
    change(inext => {
      if (inext.selected.treatments?.treatment_plans)
        inext.selected.treatments.treatment_plans.plans[props.index] = next
    })
  }
}
