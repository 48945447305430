import { useDataValue, useDataSubmit } from 'Simple/Data'

export function useOnSubmit(props) {
  let id = useDataValue({
    context: 'note_template',
    path: 'id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  return function onSubmit(value) {
    submit({ type: 'copy', locations: value, id })
    if (typeof props.closePopover === 'function') props.closePopover()
  }
}
