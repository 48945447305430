import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import { isWeekly } from 'Data/payment-plan.js'

let WEEK_DAYS = [
  {
    id: 1,
    text: 'Monday',
  },
  {
    id: 2,
    text: 'Tuesday',
  },
  {
    id: 3,
    text: 'Wednesday',
  },
  {
    id: 4,
    text: 'Thursday',
  },
  {
    id: 5,
    text: 'Friday',
  },
  {
    id: 6,
    text: 'Saturday',
  },
  {
    id: 7,
    text: 'Sunday',
  },
]

export default function useDataTransform(props, data) {
  let instalment_interval = useDataValue({
    context: 'payor',
    path: 'installment_interval',
    viewPath: props.viewPath,
  })
  return useMemo(
    () => (isWeekly(instalment_interval) ? WEEK_DAYS : getDaysOfMonth()),
    [instalment_interval]
  )
}

function getDaysOfMonth() {
  let result = []
  for (let i = 1; i <= 31; i++) {
    result.push({ id: i, text: `${i}${getSuffix(i)} of every month` })
  }
  return result
}

function getSuffix(n) {
  let s = ['th', 'st', 'nd', 'rd']
  let v = n % 100
  return s[(v - 20) % 10] || s[v] || s[0]
}
