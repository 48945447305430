import { useDataSubmit } from 'Simple/Data'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'locations',
    viewPath: props.viewPath,
  })

  return function onSubmit(selected) {
    submit({ selected })
  }
}
