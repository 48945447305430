// this is an autogenerated file from DesignSystem/PatientOverview/Content/ExternalProfessionals/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PatientOverview_Content_ExternalProfessionals($patient_id: uuid!) {
  vaxiom_professional_relationships(
    where: {
      dtype: { _eq: "otherProfessionalRelationship" }
      patient: { _id: { _eq: $patient_id } }
    }
  ) {
    id
    other {
      id
      person {
        id
        first_name
        last_name
      }
    }
  }
}

`