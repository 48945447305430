import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let organization_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  let updated_at = useMemo(() => new Date(), [])
  return {
    variables: {
      organization_id,
      updated_at,
    },
  }
}
