import { useDataChange } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onSubmit(selected_providers) {
    change(next => {
      next.selected.providers = selected_providers
    })
    if (typeof props.closePopover === 'function') props.closePopover()
  }
}

export function useOnFilter(props) {
  return function onFilter(value, items) {
    let valueRegex = new RegExp(value, 'i')
    return items.filter(item => {
      let person = item.employee_resource.employment_contract.person
      return (
        valueRegex.test(person.first_name) ||
        valueRegex.test(person.middle_name) ||
        valueRegex.test(person.last_name)
      )
    })
  }
}
