import React, { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import View from './view.js'
import styles from './slider.module.css'

export default function Logic(props) {
  let isArray = props.dataType === 'array'
  let isFloat = props.dataType === 'float'
  let showMinMaxLabel = typeof props.onMixMaxLabelFormatter !== 'undefined'

  let step = props.step
  let min = props.min
  let max = props.max

  let sliderMin = min
  let sliderMax = max
  let sliderValue = props.value

  if (isArray) {
    step = 1
    sliderMin = 0
    sliderMax = props.from.length - 1
    sliderValue = props.from.indexOf(props.value)
  } else if (isFloat) {
    sliderMin = roundToMultipleOfStep(min, step)
    sliderMax = roundToMultipleOfStep(max, step)
    sliderValue = roundToMultipleOfStep(props.value, step)
  }

  let onChange = next => {
    let value
    switch (props.dataType) {
      case 'float':
        value = mapIntValueToFloat({
          value: parseInt(next.target.value),
          min,
          max,
          sliderMin,
          sliderMax,
        })
        break
      case 'integer':
        value = parseInt(next.target.value)
        break
      case 'array':
        value = props.from[next.target.value]
        break
      default:
        value = next.target.value
    }
    props.onChange(value)
  }

  let id = useMemo(() => uuid(), [])

  return (
    <>
      <style>{`
        .${styles.slider}-${id} { 
          --backgroundColorThumb: ${props.backgroundColorThumb};
          --backgroundColorThumbDisabled: ${props.backgroundColorThumbDisabled};
          --boxShadowThumb: ${props.boxShadowThumb};
          --heightThumb: ${
            typeof props.heightThumb === 'number'
              ? `${props.heightThumb}px`
              : props.heightThumb
          };
          --widthThumb: ${
            typeof props.widthThumb === 'number'
              ? `${props.widthThumb}px`
              : props.widthThumb
          }
        }`}</style>
      <View
        {...props}
        className={`${styles.slider} ${styles.slider}-${id}`}
        value={sliderValue}
        min={sliderMin}
        max={sliderMax}
        minFormatted={
          showMinMaxLabel ? props.onMixMaxLabelFormatter(sliderMin) : undefined
        }
        maxFormatted={
          showMinMaxLabel ? props.onMixMaxLabelFormatter(sliderMax) : undefined
        }
        showMinMaxLabel={showMinMaxLabel}
        step={step}
        onChange={onChange}
      />
    </>
  )
}

function roundToMultipleOfStep(value, step) {
  return Math.floor(value / step) * step
}

function mapIntValueToFloat({ value, min, max, sliderMin, sliderMax }) {
  return roundTwoDecimals(
    ((value - sliderMin) * (max - min)) / (sliderMax - sliderMin) + min
  )
}

function roundTwoDecimals(value) {
  return Math.round(value * 100) / 100
}

Logic.defaultProps = {
  backgroundColorThumb: 'red',
}
