import { useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'discount',
    path: 'is_included_in_claim',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      next.is_included_in_claim = value

      if (value) {
        next.is_applied_to_payor = false
        next.payor_id = null
      }
    })
  }
}
