import { useDataChange, useOnChangeLastValueGuard } from 'Simple/Data.js'

export default function useListItemDataOnChange(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return useOnChangeLastValueGuard(function onChange(next) {
    change(inext => {
      inext.charges[props.index] = next
    })
  })
}
