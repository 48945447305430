import { PAYMENTS_PAYMENT_TYPES } from 'Data/constants.js'
import { useDataSubmit } from 'Simple/Data'

export function useOnPaymentPageCallback(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'payments_post_payment',
  })

  return function onPaymentPageCallback(value) {
    submit({
      type: 'form_submit',
      paymentType: PAYMENTS_PAYMENT_TYPES.PaymentPagePayment,
      value,
    })
  }
}
