import { useMemo } from 'react'
import { getDefaultPrimaryPayor } from 'Data/payment-plan.js'

// TODO: get it from treatment plan when real data is used
let MOCK_TREATMENT_FEE = 10000.0
let DEFAULT_TREATMENT_PLAN = {
  id: 1,
  length_in_weeks: 60,
}

export default function useDataTransform(props, data) {
  return useMemo(() => {
    let default_payment_plan = {
      treatment_fee: MOCK_TREATMENT_FEE,
      discounts: [],
      charges: [],
      insurances: [],
      payors: [],
    }

    return {
      ...default_payment_plan,
      payors: [
        getDefaultPrimaryPayor(default_payment_plan, DEFAULT_TREATMENT_PLAN),
      ],
    }
  }, [])
}
