// this is an autogenerated file from DesignSystem/ManualAutomations/Content/Content/ActiveAction/TaskInsert/Recipient/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ManualAutomations_Content_Content_ActiveAction_TaskInsert_Recipient($id: numeric!) {
  vaxiom_patients_by_pk(id: $id) {
    id
    _id
    person {
      id
      first_name
      last_name

      to_person_relationships(where: { permitted_to_see_info: { _eq: true } }) {
        id
        type
        from_person_record {
          id
          first_name
          last_name
        }
      }
    }
    professional_relationships {
      id
      dtype
      employee {
        id
        provider {
          id
          employee_resources(where: { invalid: { _eq: false } }) {
            id
            employment_contract {
              id
              person {
                id
                first_name
                last_name
              }
            }
          }
        }
      }
      other {
        id
        person {
          id
          first_name
          last_name
        }
      }
    }
  }
}

`