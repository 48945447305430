// this is an autogenerated file from App/Account/AutomatedEventNotifications/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_AutomatedEventNotifications_Content(
  $updated_at: timestamptz!
  $organization_id: uuid!
) {
  events_action_executions_stream(
    cursor: { initial_value: { updated_at: $updated_at }, ordering: ASC }
    batch_size: 5
    where: {
      status: { _in: [failed, completed] }
      action: {
        event: {
          source: { _in: ["ids", "itero"] }
          organization_id: { _eq: $organization_id }
        }
      }
    }
  ) {
    id
    created_at
    status
    data
    action {
      id
      event {
        id
        name
        source
      }
    }
  }
}

`