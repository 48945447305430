import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    // add an index to each merge field
    return data.map((d, index) => ({ ...d, index: index + 1 }))
  }, [data])
}
