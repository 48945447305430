import { useDataOriginalValue, useDataValue } from 'Simple/Data.js'

export function useDisabled(props) {
  let isSubmitValid = useDataValue({
    context: 'list_dialog',
    path: 'isSubmitValid',
    viewPath: props.viewPath,
  })
  let original = useDataOriginalValue({
    context: 'list_dialog',
    viewPath: props.viewPath,
  })
  let selected = useDataValue({
    context: 'list_dialog',
    path: 'selected',
    viewPath: props.viewPath,
  })

  return !isSubmitValid(original.selected, selected)
}
