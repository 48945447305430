import { AgGridReact } from 'ag-grid-react'
import { LicenseManager } from 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import './styles.css'
import React, { useEffect, useRef, useState } from 'react'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY)

let defaultColDef = {
  // cellRenderer: MyCellRenderer,
  // flex: 1,
  minWidth: 60,
  sortable: true,
  resizable: true,
  filter: true,
}

let autoGroupColumnDef = {
  cellRendererParams: {
    footerValueGetter: params => {
      let isRootLevel = params.node.level === -1
      if (isRootLevel) {
        return 'Grand Total'
      }
      return `Sub Total (${params.value})`
    },
  },
}

export default function AgGrid(props) {
  let [gridApi, setGridApi] = useState(null)
  let [columnApi, setColumnApi] = useState(null)
  let onChangeTablePresetTimeoutRef = useRef(null)

  useEffect(() => {
    if (!props.scrollToEnd || !gridApi || !props.rowData?.length) return
    // wait for resize based on content to be applied
    // first scroll to the desired row in the table which triggers loading data
    // and causes the height of the rows to be updated
    let first = setTimeout(
      () => gridApi.ensureIndexVisible(props.rowData.length - 1),
      200
    )
    // next trigger again the scrolling, but this time it should go to the end
    // as the right rows height was already set
    // ideally this second scroll should only be triggered once the whole data is loaded
    // and re-rendering finished. consider refactoring if the current approach doesn't work
    let second = setTimeout(
      () => gridApi.ensureIndexVisible(props.rowData.length - 1),
      1000
    )
    return () => {
      clearTimeout(first)
      clearTimeout(second)
    }
  }, [props.scrollToEnd, props.rowData, gridApi])

  return (
    <div
      className={`ag-theme-quartz ${
        props.verticalBorders ? 'ag-vertical-borders' : ''
      }`}
      style={{
        height: props.height,
        minHeight: props.minHeight,
        maxHeight: props.maxHeight,
        border: 'none',
        marginBottom: props.marginBottom,
      }}
    >
      {/* <PDFExportPanel gridApi={gridApi} columnApi={columnApi} /> */}
      <AgGridReact
        animateRows={true}
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={props.columnDefs}
        defaultColDef={defaultColDef}
        enableCharts
        enableRangeSelection
        groupDisplayType="multipleColumns"
        groupIncludeFooter
        rowHeight={props.rowHeight}
        headerHeight={props.headerHeight}
        groupIncludeTotalFooter={props.groupIncludeTotalFooter}
        onColumnMoved={onChangeTablePreset}
        onColumnPinned={onChangeTablePreset}
        onColumnPivotChanged={onChangeTablePreset}
        onColumnPivotModeChanged={onChangeTablePreset}
        onColumnResized={onChangeTablePreset}
        onColumnRowGroupChanged={onChangeTablePreset}
        onColumnValueChanged={onChangeTablePreset}
        onColumnVisible={onChangeTablePreset}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        onSortChanged={onChangeTablePreset}
        rowData={props.rowData}
        rowGroupPanelShow={props.rowGroupPanelShow || 'always'}
        sideBar={props.sideBar}
        statusBar={props.statusBar}
        getContextMenuItems={
          props?.shortContextMenu
            ? getShortContextMenuItems
            : getContextMenuItems
        }
        overlayNoRowsTemplate={`<span>${
          props.overlayNoRowsTemplate || 'No rows to show'
        }</span>`}
        processCellForClipboard={props.processCellForClipboard}
        isFullWidthRow={props.isFullWidthRow}
        fullWidthCellRenderer={props.fullWidthCellRenderer}
        tooltipShowDelay={props.tooltipShowDelay}
        rowSelection={props.rowSelection}
        onSelectionChanged={props.onSelectionChanged}
        {...props.gridOptions}
      />
    </div>
  )

  function onGridReady(params) {
    setGridApi(params.api)
    setColumnApi(params.columnApi)
  }

  // https://blog.ag-grid.com/persisting-ag-grid-state-with-react-redux/#example-app
  function onFirstDataRendered(params) {
    if (!columnApi || !props.tablePreset) return

    if (columnApi.isPivotMode() !== props.tablePreset.isPivotMode) {
      columnApi.setPivotMode(props.tablePreset.isPivotMode)
    }

    if (props.tablePreset.columnState) {
      columnApi.applyColumnState({
        state: props.tablePreset.columnState,
        applyOrder: true,
      })
    }

    if (props.tablePreset.columnGroupState) {
      columnApi.setColumnGroupState(props.tablePreset.columnGroupState)
    }

    // if (props.tablePreset.filterModel) {
    //   gridApi.setFilterModel(props.tablePreset.filterModel)
    // }
  }

  function onChangeTablePreset() {
    if (!columnApi || typeof props.onChangeTablePreset !== 'function') return

    clearTimeout(onChangeTablePresetTimeoutRef.current)

    onChangeTablePresetTimeoutRef.current = setTimeout(async () => {
      await props.onChangeTablePreset({
        columnState: columnApi.getColumnState(),
        columnGroupState: columnApi.getColumnGroupState(),
        isPivotMode: columnApi.isPivotMode(),
        // We aren't doing filters for now to avoid confusion
        // but this is how it would go
        // filterModel: params.api.getFilterModel(),
      })
    }, 250)
  }

  function getShortContextMenuItems(params) {
    let shortMenuItems = props?.shortMenuItems || [
      'copy',
      'separator',
      'autoSizeAll',
    ]

    // no whitelist or not a column
    if (!props.shortContextMenuColumnWhitelist || !params.column?.getColId()) {
      return shortMenuItems
    }

    // if the column is whitelisted use the provided short menu items
    return props.shortContextMenuColumnWhitelist.includes(
      params.column.getColId()
    )
      ? shortMenuItems
      : props?.shortContextMenuAlternative || []
  }

  function getContextMenuItems(params) {
    return [
      'cut',
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      'autoSizeAll',
      'expandAll',
      'contractAll',
      'separator',
      'chartRange',
      {
        name: 'Export',
        subMenu: [
          'csvExport',
          'excelExport',
          {
            name: 'PDF Export',
            action: () => onExportDataAsPDF(gridApi, columnApi),
            icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
          },
        ],
        icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
      },
    ]
  }

  // TODO: tweak PDF, left this almost as-is from ./pdfExport/PDFExportPanel.js
  async function onExportDataAsPDF(gridApi, columnApi) {
    let PDF_PAGE_ORIENTATION = 'landscape'
    let PDF_WITH_HEADER_IMAGE = false
    let PDF_WITH_FOOTER_PAGE_COUNT = 5
    let PDF_HEADER_HEIGHT = 25
    let PDF_ROW_HEIGHT = 5

    let PDF_ODD_BKG_COLOR = '#fcfcfc'
    let PDF_EVEN_BKG_COLOR = '#fff'
    let PDF_WITH_CELL_FORMATTING = true
    let PDF_WITH_COLUMNS_AS_LINKS = false
    let PDF_SELECTED_ROWS_ONLY = false

    let PDF_HEADER_COLOR = '#f8f8f8'
    let PDF_INNER_BORDER_COLOR = '#dde2eb'
    let PDF_OUTER_BORDER_COLOR = '#babfc7'
    let PDF_LOGO = '/logos/greyfinch_logo.png'

    let printParams = {
      PDF_HEADER_COLOR,
      PDF_INNER_BORDER_COLOR,
      PDF_OUTER_BORDER_COLOR,
      PDF_LOGO,
      PDF_PAGE_ORIENTATION,
      PDF_WITH_HEADER_IMAGE,
      PDF_WITH_FOOTER_PAGE_COUNT,
      PDF_HEADER_HEIGHT,
      PDF_ROW_HEIGHT,
      PDF_ODD_BKG_COLOR,
      PDF_EVEN_BKG_COLOR,
      PDF_WITH_CELL_FORMATTING,
      PDF_WITH_COLUMNS_AS_LINKS,
      PDF_SELECTED_ROWS_ONLY,
    }

    let printDoc = (await await import('./pdfExport/printDoc.js')).default
    printDoc(printParams, gridApi, columnApi)
  }
}
