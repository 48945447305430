import { useDataSubmit } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
    submit({
      type: 'tabs/add',
      tab: {
        type: 'Workflows',
        id: 'Workflows',
        name: 'Workflows',
        viewPath: '/App/Account/Content/Workflows',
        selected: {},
      },
    })
  }
}
