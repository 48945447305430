import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let types = useDataValue({
    context: 'charge_types',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'charge',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    let type = types.find(item => item.id === value)
    change(next => {
      next.type_id = type.id
      next.amount = type.price
    })
  }
}
