import { useDataSubmit, useDataValue } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  createDefaultImageReader,
  createDefaultImageWriter,
} from '@pqina/pintura'

export function useOnClick(props) {
  let editor = useDataValue({
    context: 'image',
    path: 'editor',
    viewPath: props.viewPath,
  })
  let original_image = useDataValue({
    context: 'image',
    path: 'original_image',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'image',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onClick() {
    try {
      let image = await editor.editor.processImage({
        imageReader: createDefaultImageReader(),
        imageWriter: createImageWriter(),
      })
      submit({ type: original_image ? 'create' : 'update', file: image.dest })
      setFlowTo(normalizePath(props.viewPath, '../../No'))
    } catch (error) {
      console.error(`pintura#processImage`, error)
    }
  }
}

// borrowed from pintura to avoid this issue when exporting an image in iOS
// https://pqina.nl/blog/canvas-area-exceeds-the-maximum-limit
function createImageWriter() {
  let isBrowser =
    typeof window !== 'undefined' && typeof window.document !== 'undefined'
  let isMac = isBrowser && /^mac/i.test(navigator.platform)
  let isiOSUserAgent = /iPhone|iPad|iPod/.test(navigator.userAgent)
  // eslint-disable-next-line compat/compat
  let isTouchDevice = window.navigator.maxTouchPoints >= 1

  return isBrowser && (isiOSUserAgent || (isMac && isTouchDevice))
    ? createDefaultImageWriter({
        targetSize: {
          width: 4096,
          height: 4096,
          fit: 'contain',
          upscale: true,
        },
        quality: 1,
      })
    : createDefaultImageWriter({ quality: 1 })
}
