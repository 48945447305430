import { useDataValue, useDataChange } from 'Simple/Data'
import { setMonth } from 'date-fns'

export function useOnClick(props) {
  let current_date = useDataValue({
    context: 'dropdown',
    viewPath: props.viewPath,
    path: 'date',
  })

  let change = useDataChange({
    context: 'sidebar',
    viewPath: props.viewPath,
  })

  let index = useDataValue({
    context: 'dropdown_month_item',
    viewPath: props.viewPath,
    path: 'index',
  })

  return function onClick() {
    if (index !== null) {
      change(next => {
        next.date = setMonth(current_date, index)
      })

      if (typeof props.closePopover === 'function') props.closePopover()
    }
  }
}
