import mutation from './mutation.graphql.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'id',
  })

  let vaxiom_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'vaxiom_id',
  })

  let [, executeMutation] = useMutation(mutation)
  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      access_key_encoded: btoa(
        value.provider === 'bluefin'
          ? value.bluefin.access_key
          : value.payabli.token
      ),
      account_id:
        value.provider === 'bluefin'
          ? value.bluefin.account_id
          : value.payabli.selected_paypoint,
      location_id,
      vaxiom_location_id,
      provider: value.provider,
      is_default: value.is_default,
      description:
        value.provider === 'bluefin'
          ? ''
          : JSON.stringify({
              ...value.payabli.selected_paypoint_details,
              token: btoa(
                value.payabli.token.substring(0, 6) +
                  '****' +
                  value.payabli.token.substring(value.payabli.token.length - 6)
              ),
            }),
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Payment provider added!'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
