import { useDataChange } from 'Simple/Data.js'
import { getNumberOfInstallmentsPerMonth, LOCK } from 'Data/payment-plan.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payor',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      if (value === next.installment_amount) return

      next.installment_amount = value

      if (next.payment_plan_length === 0) {
        next.payment_plan_length = 1
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.installment_interval
        )
        let monthly_installment_amount =
          value * number_of_installments_per_month
        next.downpayment = next.share.amount - monthly_installment_amount
        next.lock = LOCK.PAYMENT_PLAN_LENGTH
      } else if (value === 0) {
        // pay in full
        next.downpayment = next.share.amount
        next.payment_plan_length = 0
        next.lock = LOCK.PAYMENT_PLAN_LENGTH
      } else if (next.lock === LOCK.PAYMENT_PLAN_LENGTH) {
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.installment_interval
        )
        let total_installments_amount =
          value * next.payment_plan_length * number_of_installments_per_month
        next.downpayment = next.share.amount - total_installments_amount
      } else if (next.lock === LOCK.DOWNPAYMENT) {
        let total_installments_amount = next.share.amount - next.downpayment
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.installment_interval
        )
        let monthly_installment_amount =
          value * number_of_installments_per_month
        next.payment_plan_length = Math.ceil(
          total_installments_amount / monthly_installment_amount
        )
        let maximum_installment_amount =
          total_installments_amount / number_of_installments_per_month
        next.installment_amount = Math.min(
          parseFloat(maximum_installment_amount.toFixed(2)),
          value
        )
      }
    })
  }
}
