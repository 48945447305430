import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'expand',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.is_expanded = !next.is_expanded
    })
  }
}

export function getView(expanded) {
  return expanded ? 'Expand' : 'Close'
}
