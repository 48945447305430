import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let people = useDataValue({
    context: 'people',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payor',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.person_id = value
      next.person = people.find(item => item.id === value)
    })
  }
}
