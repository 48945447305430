// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/PatientSelect/Content/Content/Existing/AppointmentSelect/Content/Popup/Content/Appointments/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_PatientSelect_Content_Content_Existing_AppointmentSelect_Content_Popup_Content_Appointments($patient_id: uuid!) {
  unscheduled: appointments_upcoming_unscheduled_appointments_by_patient(
    args: { patient_id: $patient_id }
  ) {
    id: _id
    duration
    note
    tx {
      id: _id
      tx_card {
        id: _id
        name
      }
    }
    type {
      id: _id
      full_name
    }
  }
  scheduled: vaxiom_appointments(
    where: {
      patient: { _id: { _eq: $patient_id } }
      booking: { state: { _eq: "SCHEDULED" } }
    }
  ) {
    id: _id
    duration
    note
    tx {
      id: _id
      tx_card {
        id: _id
        name
      }
    }
    type {
      id: _id
      full_name
    }
    booking {
      id: _id
      local_start_date
      local_start_time
      start_time
      end_time
      duration
    }
  }
}

`