import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location.id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    window.postMessage({
      fakeDevelopmentCoreEmbedMessage: true,
      type: 'payments_edit_autopay',
      params: {
        patient_id,
        payment_plan_id: prompt('Payment plan ID'),
        receivable_id: prompt('Receivable ID'),
        location_id,
      },
    })
  }
}
