import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getDefaultPrimaryPayor,
  getDefaultSecondaryPayor,
} from 'Data/payment-plan.js'

export function useOnClick(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.payors.push(
        next.payors.length === 0
          ? getDefaultPrimaryPayor(next, treatment_plan)
          : getDefaultSecondaryPayor(next, treatment_plan)
      )
    })
  }
}
