import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnDelete(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'insurance_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let setEvent = useDataChange({
    context: 'event',
    viewPath: props.viewPath,
  })
  return function onDelete() {
    setEvent({
      type: 'insurance_removed',
      index,
    })
    setTimeout(
      () =>
        change(next => {
          next.insurances.splice(index, 1)
        }),
      0
    )
  }
}
