import { useDataValue } from 'Simple/Data.js'
import { useGetSignedUrl } from 'Data/s3'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let rurl = useDataValue({
    context: 'editor',
    path: 'comms_template_url',
    viewPath: props.viewPath,
  })

  let url = useGetSignedUrl({
    once: true,
    url: rurl,
    viewPath: props.viewPath,
  })

  return {
    variables: { url },
    pause: !url,
  }
}
