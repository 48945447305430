import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let appointment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'event',
    path: 'appointment._id',
  })

  return function onClick() {
    submit({ type: 'appointmentOverlay/open', appointment_id })
  }
}
