// Api works together with Auth. There are some extra notes on Auth.js that
// complement these.
//
// Tokens are renewed when needed only. That means that an access_token might
// have expired when a request comes in.
//
// Auth credentials are synced through a ref instead of the real value because
// otherwise we'd need to invalidate the client every time which would dispose
// the cache and kill in-flight transactions.
//
// The auth exchange coordinates this for queries and mutations.
//
// Subscriptions are handled by the subscription client's connection params
// function which is called whenever a connection is being established. If a
// WS connection failed, the subscription client would be reset and a token
// would be requested.
import { captureError, sessionId } from 'Logic/ErrorBoundary.js'
import { authExchange } from '@urql/exchange-auth'
import { APP_NAME, APP_API } from 'Data/constants'
import {
  createClient,
  mapExchange,
  fetchExchange,
  gql,
  Provider,
  subscriptionExchange,
  useClient,
  useMutation,
  useQuery,
  useSubscription as _useSubscription,
} from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
// import { offlineExchange as cacheExchange } from '@urql/exchange-graphcache'
// import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import { createClient as createSubscriptionClient } from 'graphql-ws'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo } from 'Simple/Flow.js'
import {
  getAccessTokenData,
  isTokenExpired as _isTokenExpired,
  refreshToken,
  useAuthRef,
} from 'Data/Auth.js'
import cacheExchangeKeys from './ApiCacheExchangeKeys.js'
import cacheExchangeUpdates from './ApiCacheExchangeUpdates.js'
import makeDebug from 'Simple/debug.js'
import React, { useEffect, useMemo } from 'react'
import { print } from 'graphql/language'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import uuid from 'uuid/v4.js'

export { gql, useClient, useMutation, useQuery }

// let storage = makeDefaultStorage({
//   idbName: 'graphcache-v3', // The name of the IndexedDB database
//   maxAge: 7, // The maximum age of the persisted data in days
// })

// TODO: replace with morpher outputting the query and subcription
// and calling a hook called useLiveQuery instead of useSubscription
export function useSubscription(_args) {
  let args = useMemo(() => {
    let text = print(_args.query)
    return {
      query: {
        ..._args,
        query: text.replace('subscription ', 'query '),
        // _stream only available as a subscription so the query will fail
        pause: /(_stream)/.test(text) || _args.pause,
      },
      subscription: _args,
      // This is an awful hack but there's a problem with updating urql's
      // cache with aggregate subscriptions. It just won't do it.
      // This hack ensures that the subscription result is returned instead
      // which is always correct.
      // If the query swap from above is done at the morpher level as suggested
      // in line 58 then more specific hooks could be used avoiding all this
      // hackery here at least.
      useSubscriptionResult:
        /(aggregate|tasks_has_pending_tasks|_stream|events_manual_event_action_executions)/.test(
          text
        ),
    }
  }, [_args])
  let query = useQuery(args.query)
  let subscription = _useSubscription(args.subscription)

  return args.useSubscriptionResult ? subscription : query
}

export function viewPathForGraphql(viewPath) {
  return `${APP_NAME.replace(/[- ]/g, '_')}__${
    viewPath
      .replace(/\(.+?\)/g, '') // without arguments
      .replace(/\//g, '_') // with _ instead of / because it isn't support by graphql
  }`
}

let debug = makeDebug('simple/Api')

export function Api(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  // We use this to get new headers without invalidating the current client
  let auth = useAuthRef(props)
  let authChange = useDataChange({ context: 'auth', viewPath: props.viewPath })
  let userId = useDataValue({
    context: 'auth',
    path: 'access_token_data.user_id',
    viewPath: props.viewPath,
  })
  let xid = useDataValue({
    context: 'auth',
    path: 'access_token_data.xid',
    viewPath: props.viewPath,
  })

  let [client, subscriptionClient] = useMemo(() => {
    let subscriptionClient = createSubscriptionClient({
      url: APP_API.replace(/^http/, 'ws').replace('api.', 'rapi.'),
      connectionParams,
      retryAttempts: 2,
      shouldRetry: () => true,
    })
    subscriptionClient.isRefreshingToken = false

    let client = createClient({
      url: APP_API,
      exchanges: [
        requestPolicyExchange({
          ttl: 300_000, // 5m
        }),
        cacheExchange({
          keys: cacheExchangeKeys.admin,
          updates: cacheExchangeUpdates,
          // storage,
        }),
        mapExchange({ onError }),
        authExchange(initAuthExchange),
        fetchExchange,
        subscriptionExchange({
          // An operation is an object that has { key, query, variables, context }
          // Hasura only cares about key, query, and variables, which is why I'm
          // ignoring context.
          forwardSubscription: ({ key, query, variables }) => ({
            subscribe: sink => ({
              unsubscribe: subscriptionClient.subscribe(
                { key, query, variables },
                sink
              ),
            }),
          }),
        }),
      ],
    })

    return [client, subscriptionClient]

    // It seems that a subscription client can only work with one role
    // at a time since the headers are only accepted by Hasura on the
    // connection_init message.
    // If we needed to support different roles, we can create a map of
    // subscription clients that uses the subscription context's role as a key
    // and accesses the right connection on subscriptionExchange.
    async function connectionParams() {
      debug({
        type: 'Api/subscriptionClient/connectionParams',
        client,
      })

      subscriptionClient.isRefreshingToken = true
      let auth = await getAuth({
        mutate: (query, variables, context) =>
          client.mutation(query, variables, context).toPromise(),
      })
      subscriptionClient.isRefreshingToken = false

      if (auth.api_role === 'public') {
        return {
          headers: withSessionId(withRequestId({ 'x-hasura-role': 'public' })),
        }
      }

      if (!auth.access_token) {
        throw new Error('Unauthorized')
      }

      return { headers: withSessionId(withRequestId(withAuthHeaders())) }
    }

    function withSessionId(headers = {}) {
      headers['x-session-id'] = sessionId
      return headers
    }

    function withRequestId(headers = {}) {
      headers['x-request-id'] = uuid()
      return headers
    }

    function withAuthHeaders(headers = {}) {
      headers['x-hasura-role'] = auth.current.api_role
      headers.Authorization = `Bearer ${auth.current.access_token}`
      return headers
    }

    function isPublicOperation(operation) {
      let fetchOptions = getOperationFetchOptions(operation)

      return (
        auth.current.access_token === null ||
        auth.current.api_role === 'public' ||
        fetchOptions.headers?.['x-hasura-role'] === 'public'
      )
    }

    function isTokenExpired() {
      return _isTokenExpired(auth.current)
    }

    async function initAuthExchange(utils) {
      return {
        addAuthToOperation,
        willAuthError,
        refreshAuth,
      }

      function addAuthToOperation(operation) {
        if (operation.kind === 'subscription') return operation

        let fetchOptions = getOperationFetchOptions(operation)
        let headers
        if (isPublicOperation(operation)) {
          // leave them as-is
          headers = { ...fetchOptions.headers }
        } else if (
          fetchOptions.headers?.['Authorization'] &&
          _isTokenExpired({
            access_token_data: getAccessTokenData(
              fetchOptions.headers['Authorization'].replace('Bearer ', '')
            ),
          })
        ) {
          // the operation has Authorization header, but JWT is expired
          // most likely it is refetching a query triggered by a mutation
          headers = withAuthHeaders(fetchOptions.headers)
        } else {
          headers = { ...withAuthHeaders(), ...fetchOptions.headers }
        }

        withSessionId(withRequestId(headers))

        _debug(headers['x-hasura-role'])

        if (auth.current.willFail) {
          operation.context.fetch = async () =>
            new Response(JSON.stringify({ message: 'unauthorized' }), {
              status: 401,
            })
        }

        return utils.appendHeaders(operation, headers)

        function _debug(api_role) {
          debug({
            type: 'Api/addAuthToOperation',
            api_role,
            kind: operation.kind,
            name: operation.query.definitions[0].name.value.split('_').pop(),
            operation,
          })
        }
      }

      function willAuthError(operation) {
        if (!isPublicOperation(operation) && isTokenExpired()) {
          debug({ type: 'Api/willAuthError', operation })
          return true
        }

        return false
      }

      async function refreshAuth() {
        if (subscriptionClient.isRefreshingToken) {
          return auth.current
        }

        return getAuth({ mutate: utils.mutate })
      }
    }

    async function getAuth({ mutate }) {
      let authData = auth.current
      debug({ type: 'Api/getAuth', auth: authData })

      if (authData.api_role === 'public' || !isTokenExpired()) {
        return authData
      }

      // The following code gets executed when an auth error has occurred
      // we should refresh the token if possible and return a new auth state
      // If refresh fails, we should log out
      let nextAuth = await refreshToken({
        mutate,
        refresh_token: authData.refresh_token,
        xid: authData.access_token_data.xid,
      })
      auth.current = nextAuth

      if (nextAuth.access_token) {
        // only do this if we have a token because if we don't
        // this will recreate the client when auth's user_id changes
        authChange(nextAuth)
      } else {
        auth.current.willFail = true
        setFlowTo(props.authSignOutView)
      }

      return nextAuth
    }

    function onError(error, operation) {
      if (didAuthError(error)) {
        setFlowTo(props.authSignOutView)
        return
      }

      if (
        error.graphQLErrors.some(
          item =>
            item.extensions?.code === 'constraint-violation' ||
            item.extensions?.code === 'validation-failed'
        )
      ) {
        // expected business logic exception (should be treated at the component level)
        return
      }

      // https://blog.sentry.io/2020/07/22/handling-graphql-errors-using-sentry/
      // https://blog.sentry.io/2021/08/31/guest-post-performance-monitoring-in-graphql/
      // https://blog.foujeupavel.com/track-error-on-your-graphql-backend-with-sentry-sdk/
      // https://the-guild.dev/graphql/envelop/plugins/use-sentry
      let errorContext = {
        graphQLErrors: error.graphQLErrors,
        networkError: error.networkError,
      }

      try {
        let [app, rviewPath] =
          operation.query.definitions[0].name.value.split('__')

        errorContext = {
          ...errorContext,
          type: operation.kind,
          viewPath: `/${rviewPath.replace(/_/g, '/')}`,
          app,
        }
      } catch (err) {
        errorContext.operation = operation
      }

      captureError(new Error(error.message), errorContext, {
        kind: errorContext.type,
        viewPath: errorContext.viewPath,
        // https://greyfinch.sentry.io/issues/4356536223
        'request-id': operation.context.fetchOptions?.headers['x-request-id'],
        'hasura-role': operation.context.fetchOptions?.headers['x-hasura-role'],
      })
    }
    function didAuthError(error, _operation) {
      return _debug(
        // the websocket brings this up
        /(unauthorized|JWT)/i.test(error?.message) ||
          /unauthorized/i.test(error?.networkError?.reason) ||
          // http connections do this instead
          error?.graphQLErrors.some(
            item =>
              /JWT/i.test(item.message) ||
              /(invalid-jwt|access-denied|jwt-invalid-claims)/i.test(
                item.extensions?.code
              )
          )
      )

      function _debug(did) {
        if (did) {
          debug({ type: 'Api/didAuthError', error, did })
        }
        return did
      }
    }
  }, [props.authSignOutView, userId, xid]) // eslint-disable-line
  // ignore auth since it's a ref

  useEffect(() => {
    return () => {
      subscriptionClient.dispose()
    }
  }, [subscriptionClient])

  return <Provider value={client}>{props.children}</Provider>
}
Api.defaultProps = {
  authSignOutView: '/App/Auth/SignOut',
}

function getOperationFetchOptions(operation) {
  return typeof operation.context.fetchOptions === 'function'
    ? operation.context.fetchOptions()
    : operation.context.fetchOptions || {}
}

export function useMutationUpdateMany(baseMutation, viewPath = '/App') {
  let client = useClient()
  return [, executeMutation]

  async function executeMutation({ objects }) {
    let [header, ...bits] = baseMutation.split('\n')
    bits.pop() // drop the final }

    let [_, baseArgs] = header.match(/\((.+)\)/)
    let argsList = baseArgs.split(',').map(item => item.trim())
    let args = objects
      .flatMap((_, index) => argsList.map(item => replace(item, index)))
      .join(', ')

    let baseBody = bits.join('\n').trim()
    let body = objects.map((_, index) => replace(baseBody, index)).join('\n')
    let operationName = viewPath.replace(/\//g, '_')
    let mutation = `mutation app__${operationName}(${args}) {
      ${body}
    }`

    let variables = {}
    objects.forEach((item, index) => {
      Object.entries(item).forEach(([key, value]) => {
        // TODO: we could infer this or work type it
        variables[`${key}${index}`] = value
      })
    })

    console.debug({
      type: 'Api/useMutationUpdateMany',
      objects,
      mutation,
      variables,
    })

    try {
      let response = await client
        .mutation(
          gql`
            ${mutation}
          `,
          variables
        )
        .toPromise()
      console.debug({ type: 'Api/useMutationUpdateMany/response', response })
      return response
    } catch (error) {
      console.error({ type: 'Api/useMutationUpdateMany/error', error })
      return { error }
    }
  }

  function replace(text, index) {
    return text.replace(/__N__/g, index)
  }
}
