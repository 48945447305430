import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let locationId = useDataValue({
    context: 'payment',
    path: 'location_id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'payment',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    submit({
      type: 'initialize',
      method: props.method,
      locationId,
      person: props.person,
    })
  }
}
