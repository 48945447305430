// this is an autogenerated file from App/Account/PatientOverlay/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content($patient_id: uuid!) {
  vaxiom_patients(where: { _id: { _eq: $patient_id } }) {
    id
    _id
    person {
      id
      greeting
      first_name
      last_name
    }
  }
}

`