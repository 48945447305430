import { numberMoney } from 'Data/format.js'
import {
  getPrimaryInsuranceEstimatedReimbursement,
  getSecondaryInsuranceEstimatedReimbursement,
} from 'Data/payment-plan.js'

export function getValue(insurance, payment_plan, treatment_plan) {
  let primary_insurance = payment_plan.insurances.find(item => item.is_primary)
  let { loss_due_to_effective_date } = insurance.is_primary
    ? getPrimaryInsuranceEstimatedReimbursement(
        insurance,
        payment_plan,
        treatment_plan
      )
    : getSecondaryInsuranceEstimatedReimbursement(
        insurance,
        primary_insurance,
        payment_plan,
        treatment_plan
      )
  return numberMoney(loss_due_to_effective_date)
}
