import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'discount',
    viewPath: props.viewPath,
  })
  let treatment_fee = useDataValue({
    context: 'payment_plan',
    path: 'treatment_fee',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      next.percentage = value
      next.amount = parseFloat(((treatment_fee * value) / 100).toFixed(2))
      next.type = 'percentage'
    })
  }
}
