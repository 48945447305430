import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let globalParams = useDataValue({
    context: 'global',
    path: 'appointment_overlay',
    viewPath: props.viewPath,
  })
  let calendarParams = useDataValue({
    context: 'tab',
    path: 'appointment_overlay',
    viewPath: props.viewPath,
  })

  /*
    Appointment overlay can be open from timegrid or from an external component like Patient Overlay
    (and in the future, we might open it from a patient's tx card)
    In order to not reload the entire calendar tab data when we are already in this tab but in 
    an external component like Patient Overlay at the same time, it has its own data context
  */
  return useMemo(() => {
    let is_global_call = false
    let patient_id = calendarParams?.patient_id
    let appointment_id = calendarParams?.appointment_id

    if (globalParams?.patient_id || globalParams?.appointment_id) {
      is_global_call = true
      patient_id = globalParams.patient_id
      appointment_id = globalParams.appointment_id
    }

    return {
      ...data,
      open: patient_id || appointment_id,
      is_global_call,
      patient_id,
      appointment_id: appointment_id === 'new' ? null : appointment_id,
    }
  }, [data, globalParams, calendarParams])
}
