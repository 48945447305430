import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

export default function useDataTransform(props, data) {
  let selected_date = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.date',
  })

  return useMemo(() => {
    return {
      date: selected_date,
    }
  }, [selected_date])
}
