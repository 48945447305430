// this is an autogenerated file from DesignSystem/PatientOverview/Content/ResponsibleRelatives/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PatientOverview_Content_ResponsibleRelatives($person_id: uuid!) {
  vaxiom_relationships(
    where: {
      to_person_record: { _id: { _eq: $person_id } }
      role: { _eq: "RESPONSIBLE" }
    }
  ) {
    id
    type
    role
    from_person_record {
      id
      first_name
      middle_name
      last_name
      emails: contact_method_associations(
        where: { contact_method: { dtype: { _eq: "email" } } }
        limit: 1
      ) {
        id
        email {
          id
          address
        }
      }
      phones: contact_method_associations(
        where: { contact_method: { dtype: { _eq: "phone" } } }
        limit: 1
      ) {
        id
        phone {
          id
          number
        }
      }
    }
  }
}

`