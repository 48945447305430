import { useDataChange } from 'Simple/Data.js'

export default function useDataOnChange(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    let settings = value?.[0]
    if (!settings) {
      change(next => {
        next.selected.slot_interval = 5
        next.selected.hipaa_active = false
        next.selected.topbar_appointment_types = []
      })
    } else {
      change(next => {
        next.selected.slot_interval = settings.slot_interval
        next.selected.hipaa_active = settings.hipaa
        next.selected.topbar_appointment_types =
          settings.selected_appointment_templates
      })
    }
  }
}
