import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getAdjustedTreatmentFeeAfterInsurances,
  getUpdatedPayor,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let payment_plan = useDataValue({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let payor = useDataValue({
    context: 'payor',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payor',
    viewPath: props.viewPath,
  })
  let setEvent = useDataChange({
    context: 'event',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    let treatment_fee = getAdjustedTreatmentFeeAfterInsurances(
      payment_plan,
      treatment_plan
    )
    let target_share = {
      type: 'amount',
      amount: value,
      percentage:
        treatment_fee > 0 ? Math.round((value / treatment_fee) * 100) : 0,
    }
    change(getUpdatedPayor(payor, target_share))

    // force update of the other payor's share
    setTimeout(
      () =>
        setEvent({
          type: 'payor_share_updated',
          index,
        }),
      0
    )
  }
}
