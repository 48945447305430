// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/TreatmentCard/Summary/Content/Content/Status/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_TreatmentCard_Summary_Content_Content_Status($type: String!, $organization_id: numeric!) {
  vaxiom_tx_statuses(
    where: {
      type: { _eq: $type }
      parent_company_id: { _eq: $organization_id }
      hidden: { _eq: false }
    }
  ) {
    id
    name
  }
}

`