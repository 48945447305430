import {
  APP_ENV,
  BLUEFIN_WIDGET_URL,
  PAYABLI_TOKEN,
  PAYABLI_WIDGET_URL,
} from 'Data/constants.js'

export default async function setup({ provider }) {
  switch (provider) {
    case 'bluefin': {
      return setupBluefin()
    }
    case 'payabli': {
      return setupPayabli()
    }
    default: {
      throw new Error(`Unkwnown payment provider ${provider}`)
    }
  }
}

async function setupBluefin() {
  let Api = await getApi('bluefin')

  return function render({ accountId, method, containerId, onValid }) {
    let api = new Api({
      create: true,
      iframeId: `${containerId}-iframe`,
      settings: {
        account: accountId,
        payment_method: BLUEFIN_METHOD_MAP[method],
        parentId: containerId,
        ...makeConfigBluefin(),
        // height: method === 'cc' ? CONFIG_BLUEFIN.height : 'fit-content',
      },
    })

    // mock it, couldn't find it on Bluefin's docs
    // we need delay the onValid call, for state to update correctly
    setTimeout(() => onValid(true), 0)

    return async function submit() {
      return new Promise((resolve, reject) => {
        api
          .encrypt()
          .success(args => {
            resolve(args)
            api.clear()
          })
          .failure(args => {
            reject(args)
            api.clear()
          })
          .invalidInput(reject)
      })
    }
  }
}

async function setupPayabli() {
  let Api = await getApi(
    'payabli',
    APP_ENV === 'production' ? {} : { 'data-test': '' }
  )

  return function render({ accountId, method, containerId, person, onValid }) {
    let submitPromiseResolve
    let submitPromiseReject
    let submitPromise = new Promise((resolve, reject) => {
      submitPromiseResolve = resolve
      submitPromiseReject = reject
    })
    let api = new Api({
      rootContainer: containerId,
      entryPoint: accountId,
      ...makeConfigPayabli({
        firstName: person.first_name,
        lastName: person.last_name,
      })[method],
      token: PAYABLI_TOKEN,
      // see DesignSystem/PaymentProvider/specs.md
      customCssUrl: `${window.location.origin}/payabli/style.css`,
      customerData: {
        customerNumber: person._id,
        firstName: person.first_name,
        lastName: person.last_name,
      },
      temporaryToken: true,
      functionCallBackSuccess: response => {
        if (response.isSuccess) {
          submitPromiseResolve(response.responseData)
        } else {
          submitPromiseReject(response.responseData)
        }

        api.payabliExec('reinit')
      },
      functionCallBackError: errors => {
        submitPromiseReject(errors)
        api.payabliExec('reinit')
      },
      functionCallBackReady: ([_method, valid]) => onValid(valid),
    })

    return async function submit() {
      submitPromise = new Promise((resolve, reject) => {
        submitPromiseResolve = resolve
        submitPromiseReject = reject
      })
      api.payabliExec('method')
      return submitPromise
    }
  }
}

let BLUEFIN_METHOD_MAP = {
  card: 'cc',
  ach: 'ach',
}

function makeConfigBluefin() {
  return {
    ...(APP_ENV !== 'production'
      ? { devServer: 'https://cert.payconex.net' }
      : {}),
    lang: 'en',
    cvv: 'required',
    expy: 'single_input',
    showFrame: false,
    labelFontFamily: '1',
    css: {
      class_input_box: `font-family: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        background: #FFFFFF;
        border: 1px solid #CBD2D9;
        padding: 8px 16px 8px 16px;
        width: 100%;
        color: #1F2933;
        border-radius: 8px;`,
      'class_input_box::placeholder': 'color: #7B8794;',
      class_label: `font-family: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        display: inline-block;
        width: 100%;
        color: #1F2933;
        margin-top: 8px;
        margin-bottom: 4px;`,
      'class_input_box:focus': 'outline: none; border-color: #00BC70;',
      id_number_row: 'display: inline-block; width: 100%; margin-bottom: 8px;',
      id_expy_row: 'display: inline-block; width: 47%; margin-right: 3%;',
      id_expy_input: '',
      id_cvv_row: 'display: inline-block; width: 47%; margin-left: 3%;',
      id_cvv_input: '',
      id_routing_input: 'width: 95%;',
      id_account_input: 'width: 95%;',
    },
    text: {
      number: {
        label: 'Card number',
        placeholder: '1234 1234 1234 1234',
      },
      cvv: {
        label: 'CVV',
        placeholder: '123',
      },
      expy_single: {
        label: 'Expiry date',
        placeholder: '0130',
      },
    },
  }
}

/** @param {{ firstName: string; lastName: string }} input */
function makeConfigPayabli({ firstName, lastName }) {
  let defaultHolderName = `${firstName} ${lastName}`

  return {
    card: {
      type: 'methodEmbedded',
      defaultOpen: 'card',
      card: {
        enabled: true,
        amex: true,
        discover: true,
        visa: true,
        mastercard: true,
        inputs: {
          cardHolderName: {
            label: 'Name on card',
            placeholder: '',
            floating: false,
            size: 12,
            row: 0,
            order: 0,
            value: defaultHolderName,
          },
          cardNumber: {
            label: 'Card number',
            placeholder: '1234 1234 1234 1234',
            floating: false,
            size: 12,
            row: 1,
            order: 0,
          },
          cardExpirationDate: {
            label: 'Expiry date',
            placeholder: '01/30',
            floating: false,
            size: 4,
            row: 2,
            order: 0,
          },
          cardCvv: {
            label: 'CVV',
            placeholder: '123',
            floating: false,
            size: 4,
            row: 2,
            order: 1,
          },
          cardZipcode: {
            label: 'Zip/Postal code',
            placeholder: '',
            floating: false,
            size: 4,
            row: 2,
            order: 3,
          },
        },
      },
      ach: { enabled: false },
    },
    ach: {
      type: 'methodEmbedded',
      height: '500px',
      defaultOpen: 'ach',
      card: {
        enabled: false,
      },
      ach: {
        enabled: true,
        checking: true,
        savings: true,
        inputs: {
          achAccountHolderName: {
            label: 'Holder name',
            size: 6,
            floating: false,
            row: 1,
            order: 0,
            value: defaultHolderName,
          },
          achAccountType: {
            label: 'Type',
            floating: false,
            size: 6,
            row: 1,
            order: 1,
          },
          achRouting: {
            label: 'Routing number',
            floating: false,
            size: 6,
            row: 2,
            order: 0,
            confirm: true,
          },
          achAccount: {
            label: 'Account number',
            floating: false,
            size: 6,
            row: 3,
            order: 1,
            confirm: true,
          },
        },
      },
    },
  }
}

let queue = {}
async function loadScript(url, attrs) {
  if (!queue[url]) {
    queue[url] = load(url, attrs)
  }
  return queue[url]
}

async function getApi(provider, attrs = {}) {
  switch (provider) {
    case 'bluefin': {
      await loadScript(BLUEFIN_WIDGET_URL, attrs)
      // @ts-ignore
      // eslint-disable-next-line
      if (window.PaymentiFrame) {
        // @ts-ignore
        // eslint-disable-next-line
        return window.PaymentiFrame
      }
      break
    }
    case 'payabli': {
      await loadScript(PAYABLI_WIDGET_URL, attrs)
      // this isn't on window :shrug:
      // @ts-ignore
      // eslint-disable-next-line
      if (PayabliComponent) {
        // @ts-ignore
        // eslint-disable-next-line
        return PayabliComponent
      }
      break
    }
    default: {
      throw new Error(`Unkwnown payment provider ${provider}`)
    }
  }

  throw new Error(`Failed to get API for ${provider}`)
}

/**
 * Load script with timeout. Throws an error if the script cannot be loaded within the specified timeframe.
 *
 * @param {*} src
 * @param {*} attrs
 * @param {*} parentNode
 * @param {*} timeout
 * @returns
 */
async function load(src, attrs, parentNode, timeout = 10000) {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script')
    script.async = true
    script.src = src

    // eslint-disable-next-line compat/compat
    for (let [k, v] of Object.entries(attrs || {})) {
      script.setAttribute(k, v)
    }
    let id = setTimeout(() => {
      reject(
        new Error(`Failed to load ${src} after ${timeout / 1000} seconds.`)
      )
    }, timeout)

    script.onload = () => {
      script.onerror = script.onload = null
      clearTimeout(id)
      resolve(script)
    }

    script.onerror = () => {
      script.onerror = script.onload = null
      clearTimeout(id)
      reject(new Error(`Failed to load ${src}`))
    }

    let node =
      parentNode || document.head || document.getElementsByTagName('head')[0]
    node.appendChild(script)
  })
}
