import { useDataChange } from 'Simple/Data.js'
import { getNumberOfInstallmentsPerMonth, LOCK } from 'Data/payment-plan.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payor',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      if (value === next.downpayment) return

      next.downpayment = value

      if (value === next.share.amount) {
        // no installment amount or installment length as paid in full
        next.installment_amount = 0
        next.payment_plan_length = 0
        next.lock = LOCK.PAYMENT_PLAN_LENGTH
      } else if (next.payment_plan_length === 0) {
        next.payment_plan_length = 1
        let total_installments_amount = next.share.amount - value
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.installment_interval
        )
        next.installment_amount =
          total_installments_amount / number_of_installments_per_month
        next.lock = LOCK.PAYMENT_PLAN_LENGTH
      } else if (next.lock === LOCK.PAYMENT_PLAN_LENGTH) {
        let total_installments_amount = next.share.amount - value
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.installment_interval
        )
        let total_number_of_installments =
          next.payment_plan_length * number_of_installments_per_month
        let installment_amount =
          total_installments_amount / total_number_of_installments

        next.installment_amount = parseFloat(installment_amount.toFixed(2))
      } else if (next.lock === LOCK.INSTALLMENT_AMOUNT) {
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.installment_interval
        )
        let monthly_installment_amount =
          next.installment_amount * number_of_installments_per_month
        // prevent it from going over 100% of the payor's share
        let downpayment = Math.min(
          value,
          next.share.amount - monthly_installment_amount
        )
        let total_installments_amount = next.share.amount - downpayment

        next.payment_plan_length = Math.ceil(
          total_installments_amount / monthly_installment_amount
        )
        next.downpayment = downpayment
      }
    })
  }
}
