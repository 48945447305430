import { useAuthRef } from 'Data/Auth.js'
import { useDataValue } from 'Simple/Data.js'

export function useGetUrl(props) {
  let locationId = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  let authRef = useAuthRef({
    viewPath: props.viewPath,
  })

  return function getUrl(value) {
    return value
      .replace(/\{\{pms.access_token\}\}/g, authRef.current.access_token)
      .replace(/\{\{pms.location_id\}\}/g, locationId)
  }
}
