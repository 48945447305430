import addWeeks from 'date-fns/addWeeks/index.js'
import format from 'date-fns/format/index.js'

export function calculateStartDate(appointments, appointment_id) {
  let current_index = appointments?.findIndex(
    item => item.id === appointment_id
  )

  let weeks = appointments
    .filter((item, index) => index < current_index)
    .reduce((acc, item) => {
      acc += item.interval_to_next ?? 0

      return acc
    }, 0)

  return format(addWeeks(new Date(), weeks), 'MMMM, yyyy')
}
