import { useDataSubmit, useDataValue, useDataIsSubmitting } from 'Simple/Data'
import { textToNumber } from 'Simple/Data/format.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'new_payment_plan',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'create_payment_plan' })
  }
}

export function useIsDisabled(props) {
  let balance_amount = useDataValue({
    context: 'receivable',
    viewPath: props.viewPath,
    path: 'balance_amount',
  })
  let value = useDataValue({
    context: 'new_payment_plan',
    viewPath: props.viewPath,
  })
  let isSubmitting = useDataIsSubmitting({
    context: 'new_payment_plan',
    viewPath: props.viewPath,
  })
  let paymentMethodValid = useDataValue({
    context: 'payment',
    path: 'valid',
    viewPath: props.viewPath,
  })

  return (
    isSubmitting ||
    validate(value).isInvalid ||
    textToNumber(value.invoice_amount) > balance_amount ||
    (!paymentMethodValid && value.selected_payment_source === 'autopay')
  )
}

export function validate(value) {
  let errors = []

  let {
    selected_payment_source,
    selected_autopay_source,
    invoice_amount,
    installment_amount,
    selected_installment_interval,
    selected_installment_date,
    selected_first_installment_date,
    plan_length,
  } = value

  if (selected_installment_interval === null) {
    errors.push('Please select an installment interval')
  }

  if (selected_installment_date === null) {
    errors.push('Please select an installment date')
  }

  if (selected_first_installment_date === null) {
    errors.push('Please select a first installment date')
  }

  if (plan_length === null || plan_length === 0) {
    errors.push('Plan length cannot be 0')
  }

  if (textToNumber(installment_amount) === 0) {
    errors.push('Amount to be paid every installment cannot be zero')
  }

  if (textToNumber(invoice_amount) === 0) {
    errors.push('Amount to be paid cannot be zero')
  }

  if (selected_payment_source === null) {
    errors.push('Please select a payment source (Autopay or Invoice)')
  } else if (
    selected_payment_source === 'autopay' &&
    selected_autopay_source === null
  ) {
    errors.push('Please select an autopayment source')
  }

  return {
    isInvalid: errors.length > 0,
    errors,
  }
}
