import {
  NOTE_TEMPLATE_TAGS,
  NOTE_TEMPLATE_EDITOR_VARIANTS,
} from 'Data/constants.js'

export function tagsToEditor(tags) {
  return (
    NOTE_TEMPLATE_TAGS[tags[0]]?.editorVariant ??
    NOTE_TEMPLATE_EDITOR_VARIANTS.richtext
  )
}
