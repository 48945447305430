import { useDataSubmit } from 'Simple/Data'
import { textToNumber } from 'Simple/Data/format.js'

export function calcRemainingAmount(invoice_amount, total_amount) {
  return `$${Math.max(0, total_amount - textToNumber(invoice_amount))}`
}

export function isError(invoice_amount, total_amount) {
  return textToNumber(invoice_amount) > total_amount
}

export function useOnChange(props) {
  let submit = useDataSubmit({
    context: 'new_payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    submit({ type: 'update_invoice_amount', value })
  }
}
