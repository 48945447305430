import { useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.sidebar.isFloating = true
      next.sidebar.isFloatingAndVisible = false
    })
  }
}
