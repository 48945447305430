import formatDuration from 'date-fns/formatDuration/index.js'
import addWeeks from 'date-fns/addWeeks/index.js'
import getDay from 'date-fns/getDay/index.js'
import getDate from 'date-fns/getDate/index.js'
import intervalToDuration from 'date-fns/intervalToDuration/index.js'
import format from 'date-fns/format/index.js'
import startOfMonth from 'date-fns/startOfMonth/index.js'

function formatWeek(week) {
  if (week < 2) {
    return 'Early'
  } else if (week < 4) {
    return 'Mid'
  } else {
    return 'Late'
  }
}

export function getSubtitle(appointments) {
  let weeks = appointments.reduce((acc, item) => acc + item.interval_to_next, 0)
  let now = new Date()
  let futureDate = addWeeks(now, weeks)

  let firstDayOfMonth = startOfMonth(futureDate)

  // Get the day of the week of the first day of the month (0 = Sunday, 6 = Saturday)
  let startDayOfWeek = getDay(firstDayOfMonth)

  // Get the current day of the month (1-31)
  let currentDay = getDate(futureDate)

  // Calculate the number of days passed since the start of the month
  let daysPassed = currentDay + startDayOfWeek - 1

  let weekNumber = Math.ceil(daysPassed / 7)

  return `${formatDuration(
    intervalToDuration({ start: now, end: futureDate }),
    {
      format: ['years', 'months', 'weeks'],
    }
  )} (projected end ${formatWeek(weekNumber)}-${format(
    futureDate,
    'MMMM yyyy'
  )})`
}
