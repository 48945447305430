// this is an autogenerated file from App/Account/Topbar/Options/Content/Apps/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Topbar_Options_Content_Apps_Content($type: String!) {
  apps_launchers(type: $type) {
    launchers
  }
}

`