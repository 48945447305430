// this is an autogenerated file from DesignSystem/ManualAutomations/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_ManualAutomations_Content($ids: [uuid!]!) {
  events_action_executions(where: { id: { _in: $ids } }) {
    id
    status
    data
    is_required
    event_execution {
      id
      data
    }
    action {
      id
      type
      data
      event {
        id
        type
        trigger_type
        data
      }
    }
  }
}

`