// this is an autogenerated file from DesignSystem/PatientOverview/Content/Comms/Actions/Content/ContactMethods/queryDiscussion.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PatientOverview_Content_Comms_Actions_Content_ContactMethods($resources: jsonb) {
  comms_threads(
    where: { resources: { _contains: $resources }, type: { _eq: internal } }
  ) {
    id
  }
}

`