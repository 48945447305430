import React from 'react'
import View from './view.js'
import useIsHovered from 'Simple/hooks/useIsHovered.js'
import { useFloating, flip } from '@floating-ui/react-dom'

export default function Logic(props) {
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: props.disabled,
    isSelected: false,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
  })

  // Popover floating content
  let { refs, x, y } = useFloating({
    open: hovered,
    placement: props.placement || 'bottom-start',
    strategy: 'fixed',
    middleware: [flip()],
  })

  return (
    <View
      {...props}
      hovered={hovered}
      onMouseEnter={hoverBind.onMouseEnter}
      onMouseLeave={hoverBind.onMouseLeave}
      reference={refs.setReference}
      floating={refs.setFloating}
      top={y}
      left={x}
    />
  )
}
