import { age, dateShortIn } from 'Data/format.js'

export function format(person) {
  /** @type {(string | number)[]} */
  let result = [`${person.first_name} ${person.last_name}`]

  if (person.gender) result.push(person.gender[0])

  if (person.birth_date) {
    result.push(age(person.birth_date), dateShortIn(person.birth_date))
  }

  return result.filter(Boolean).join(', ')
}
