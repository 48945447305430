// this is an autogenerated file from DesignSystem/CommsThreadTextarea/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThreadTextarea($organization_id: uuid!, $pattern: String!) {
  notes_templates(
    where: {
      organization_id: { _eq: $organization_id }
      name: { _ilike: $pattern }
      tags: { _contains: "comms" }
    }
    order_by: { name: asc }
  ) {
    id
    value: url
    text: name
  }
}

`