import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnDelete(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  return function onDelete() {
    change(next => {
      next.payors.splice(index, 1)
    })
  }
}
