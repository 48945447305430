import { listEquals } from 'Data/aggregate.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    let items = props.items || data.items
    let selected = props.selected || data.selected

    return {
      ...data,
      items,
      filtered_items: items,
      selected,
      onFilter:
        typeof props.onFilter === 'function' ? props.onFilter : onFilter,
      isSubmitValid:
        typeof props.onValidateSubmit === 'function'
          ? props.onValidateSubmit
          : onValidateSubmit,
    }
  }, [
    data,
    props.items,
    props.selected,
    props.onFilter,
    props.onValidateSubmit,
  ])
}

// Default function to filter items by name
function onFilter(value, items) {
  let valueRegex = new RegExp(value, 'i')
  return items.filter(item => valueRegex.test(item.name))
}

// Default function to validate submit
function onValidateSubmit(originalSelected, selected) {
  return !listEquals(originalSelected, selected)
}
