import { addMinutes } from 'date-fns'
import { toDate, formatInTimeZone } from 'date-fns-tz'

export function getBookingTime(booking, time_zone_id) {
  let start_time = toDate(
    `${booking.local_start_date}T${booking.local_start_time}`,
    { timeZone: time_zone_id }
  )
  let end_time = addMinutes(start_time, booking.duration)
  return `${formatInTimeZone(
    start_time,
    time_zone_id,
    'MMM dd, hh:mm'
  )} - ${formatInTimeZone(end_time, time_zone_id, 'hh:mmaaa')}`
}
