// this is an autogenerated file from DesignSystem/PatientOverview/Content/Contact/Content/HubAccess/OkAccountMissing/InviteAction/Content/Email/sendHubInviteMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PatientOverview_Content_Contact_Content_HubAccess_OkAccountMissing_InviteAction_Content_Email($email: citext!, $location_id: numeric!) {
  auth_patient_invite(email: $email, location_id: $location_id) {
    ok
  }
}

`