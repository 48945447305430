/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'appointmentOverlay/open': {
        change(next => {
          next.appointment_overlay.appointment_id = args.appointment_id
        })
        break
      }
      case 'appointmentOverlay/close': {
        change(next => {
          next.appointment_overlay.appointment_id = null
        })
        break
      }
      default: {
        break
      }
    }
  }
}
