import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { patientName } from 'Data/format.js'

export function useOnClick(props) {
  let person = useDataValue({
    context: 'person',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let is_new_calendar_enabled = useDataValue({
    context: 'global',
    viewPath: props.viewPath,
    path: 'feature_flags.is_new_calendar_enabled',
  })

  return function onClick() {
    if (person.is_patient) {
      if (is_new_calendar_enabled) {
        submit({
          type: 'patientOverlay/open',
          patient_id: person.patient._id,
        })
      } else {
        submit({
          type: 'tabs/add',
          tab: {
            id: person.patient._id,
            type: 'Patient',
            name: patientName(person),
            tab: 'profile',
            viewPath: '/App/Account/Content/Patients',
            patient_id: person.patient._id,
          },
        })
      }

      setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
      return
    }

    if (person.is_external_professional) {
      setFlowTo(
        normalizePath(props.viewPath, '../EditOtherProfessional/Content')
      )
      return
    }
  }
}
