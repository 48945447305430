// this is an autogenerated file from App/Account/Content/Calendar/New/Topbar/Content/FilterAction/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Topbar_Content_FilterAction_Content($location_id: uuid!) {
  vaxiom_appointment_templates(
    where: {
      organization: { _id: { _eq: $location_id } }
      deleted: { _eq: false }
    }
    order_by: { full_name_computed: asc }
  ) {
    id: _id
    vaxiom_id: id
    full_name: full_name_computed
    color_id: color_id_computed
  }
}

`