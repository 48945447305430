import { startOfDay } from 'date-fns'
import { utcToZonedTime, format } from 'date-fns-tz'
import { useDataChange } from 'Simple/Data'

export function isToday(date, time_zone_id) {
  return (
    format(
      startOfDay(utcToZonedTime(new Date(), time_zone_id)),
      'yyyy-MM-dd'
    ) === format(date, 'yyyy-MM-dd')
  )
}

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      let date = startOfDay(
        utcToZonedTime(new Date(), next.selected.time_zone_id)
      )
      next.selected.date = date
    })
  }
}
