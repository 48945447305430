import { numberMoney } from 'Data/format.js'

export function getLastInstallmentAmount(
  installment_amount,
  payment_plan_length,
  payor_share,
  downpayment
) {
  if (payment_plan_length === 0 || installment_amount === 0) {
    return numberMoney(0)
  } else {
    let total_installments_amount = payor_share - downpayment
    let number_of_installments = Math.ceil(
      total_installments_amount / installment_amount
    )
    return numberMoney(
      total_installments_amount -
        installment_amount * (number_of_installments - 1)
    )
  }
}
