import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let types = useDataValue({
    context: 'discount_types',
    viewPath: props.viewPath,
  })
  let treatment_fee = useDataValue({
    context: 'payment_plan',
    path: 'treatment_fee',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'discount',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    let type = types.find(item => item.id === value)
    change(next => {
      next.type_id = type.id

      if (type.default_amount) {
        next.type = 'amount'
        next.amount = type.default_amount
        // dynamically calculate the equivalent percentage for the given amount
        next.percentage =
          treatment_fee > 0 ? (type.default_amount / treatment_fee) * 100 : 0
      } else {
        next.type = 'percentage'
        next.percentage = type.default_percentage
        next.amount = treatment_fee * (type.default_percentage / 100)
      }
    })
  }
}
