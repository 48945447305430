// this is an autogenerated file from DesignSystem/PatientOverview/Content/Comms/Actions/Content/ContactMethods/mutationSms.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PatientOverview_Content_Comms_Actions_Content_ContactMethods($number: PhoneNumber!) {
  thread: comms_threads_sms_create(number: $number) {
    id
  }
}

`