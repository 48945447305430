import { normalizePath, useSetFlowTo } from 'Simple/Flow'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    setFlowTo([
      normalizePath(props.viewPath, '../../No'),
      '/App/Account/Content/Profile',
    ])
  }
}
