import React, { useLayoutEffect, useRef } from 'react'
import View from './view.js'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let locationId = useDataValue({
    context: 'payment',
    path: 'location_id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'payment',
    viewPath: props.viewPath,
  })
  let submitRef = useRef(submit)
  let onPaymentPageCallback = useRef(props.onPaymentPageCallback)

  useLayoutEffect(() => {
    submitRef.current = submit
    onPaymentPageCallback.current = props.onPaymentPageCallback
  }, [submit, props.onPaymentPageCallback])

  useLayoutEffect(() => {
    if (!locationId || !props.person?._id || !props.method) return

    submitRef.current({
      type: 'initialize',
      method: props.method,
      locationId,
      person: props.person,
      amount: props.amount || null,
      onPaymentPageCallback: args => onPaymentPageCallback.current(args),
    })
  }, [props.method, locationId, props.person, props.amount])

  return <View {...props} />
}
