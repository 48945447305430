import React from 'react'
import View from './view.js'

export default function Logic(props) {
  let [timePart, range] = props.time.split(/(am|pm)/).filter(Boolean)
  let [hours, minutes] = timePart.split(':')

  return (
    <View
      {...props}
      hours={!minutes && hours}
      range={!minutes && range}
      minutes={minutes || '00'}
    />
  )
}
