import React from 'react'
import { useDataIsSubmitting, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })
  let note_template = useDataValue({
    context: 'note_templates_tab',
    path: 'note_template',
    viewPath: props.viewPath,
  })
  return (
    <View
      disabled={
        isSubmitting || !isValid(note_template) || !hasChanges(note_template)
      }
      {...props}
    />
  )
}

function isValid(value) {
  return (
    value.name?.length &&
    value.content?.length > 0 &&
    value.content?.length < 6000 &&
    value.tags.length
  )
}

function hasChanges(value) {
  if (!value.id) return true
  return (
    value.name !== value.original.name ||
    value.tags.some(item => !value.original.tags.includes(item)) ||
    value.content !== value.original.content
  )
}
