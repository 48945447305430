import { normalizePath, useSetFlowTo } from 'Simple/Flow'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    window.open('https://greyfinch.canny.io', '_blank')
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
