import { useDataValue } from 'Simple/Data.js'
import { useEffect } from 'react'
import './logic.css'
import View from './view.js'

export default function Logic(props) {
  let editorApi = useDataValue({
    context: 'editor',
    path: 'api',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (
      !props.value &&
      editorApi &&
      editorApi.getContent({ format: 'text' }) !== ''
    ) {
      editorApi.resetContent('')
    }
  }, [props.value])

  return <View {...props} />
}
