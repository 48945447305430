import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isBefore from 'date-fns/isBefore'
import startOfDay from 'date-fns/startOfDay'
import { useDataValue } from 'Simple/Data.js'
import { isAppointmentScheduled, stripHtml, hasHTML } from 'Data/format.js'

import View from './view.js'
// TODO: find a better way to render the columns, preferrably directly from view.blocks files
import ActionsCell from './ActionsCell/index.js'
import AppointmentCell from './AppointmentCell/index.js'
import NotesCell from './NotesCell/index.js'
import NotesCellTooltip from './NotesCellTooltip/index.js'
import TreatmentFieldCell from './TreatmentFieldCell/index.js'
import NotesHeader from './NotesHeader/index.js'
import TreatmentNoteRow from './TreatmentNoteRow/index.js'
import OrderRow from './OrderRow/index.js'
import CustomResourceRow from './CustomResourceRow/index.js'
import { equals } from 'Data/aggregate'

let ROW_HEIGHT = 56
let HEADER_HEIGHT = 31
let MAX_TABLE_HEIGHT = 310 // showing 5 rows

let toolTipValueGetter = params => ({ value: params.value })
export default function Logic(props) {
  let appointment_notes = useDataValue({
    context: 'copy_notes',
    path: 'appointment_notes',
    viewPath: props.viewPath,
  })
  let treatment_notes = useDataValue({
    context: 'copy_notes',
    path: 'treatment_notes',
    viewPath: props.viewPath,
  })
  let events = useDataValue({
    context: 'events',
    viewPath: props.viewPath,
  })
  let treatment_fields = useDataValue({
    context: 'treatment_fields',
    viewPath: props.viewPath,
  })
  let compact_view_notes = useDataValue({
    context: 'treatment_card_settings',
    path: 'data.compact_view_notes',
    viewPath: props.viewPath,
  })

  let [pastAppointments, setPastAppointments] = useState([])

  useEffect(() => {
    let currentPastAppointments = Array.isArray(events)
      ? events.filter(
          event =>
            (event.type === 'appointment' &&
              isAppointmentScheduled(event.appointment) &&
              isBefore(
                new Date(`${event.appointment.booking.start_time}Z`),
                startOfDay(new Date())
              )) ||
            (['note', 'order', 'custom_resource'].includes(event.type) &&
              isBefore(
                new Date(`${event.created_at}Z`),
                startOfDay(new Date())
              ))
        )
      : []

    if (!equals(pastAppointments, currentPastAppointments)) {
      setPastAppointments(currentPastAppointments)
    }
  }, [events])

  let height = useMemo(() => {
    if (compact_view_notes === 'full') {
      return MAX_TABLE_HEIGHT
    }

    // include header's height in the calculation
    return Math.min(
      Math.max(pastAppointments.length * ROW_HEIGHT + HEADER_HEIGHT, 100),
      MAX_TABLE_HEIGHT
    )
  }, [compact_view_notes, pastAppointments])

  let columnDefs = useMemo(
    () => [
      {
        field: 'appointment_summary',
        headerName: 'Appointment',
        wrapText: true,
        autoHeight: true,
        initialWidth: 300,
        cellRenderer: params => (
          <AppointmentCell
            {...params}
            key={params.data.id}
            viewPath={`${props.viewPath}/AppointmentCell(${params.data.id})`}
          />
        ),
      },
      {
        field: 'notes',
        autoHeight: compact_view_notes === 'full',
        initialWidth: 400,
        headerComponent: params => (
          <NotesHeader {...params} viewPath={`${props.viewPath}/NotesHeader`} />
        ),
        cellRenderer: params => (
          <NotesCell
            {...params}
            key={params.data.id}
            viewPath={`${props.viewPath}/NotesCell(${params.data.id})`}
          />
        ),
        tooltipValueGetter: toolTipValueGetter,
        tooltipComponent: params => (
          <NotesCellTooltip
            {...params}
            viewPath={`${props.viewPath}/NotesCellTooltip(${params.data.id})`}
          />
        ),
      },
      ...treatment_fields.map(treatment_field => ({
        field: `${treatment_field.id}`,
        headerName: treatment_field.short_name || treatment_field.name,
        initialWidth: 100,
        cellRenderer: params => (
          <TreatmentFieldCell
            {...params}
            key={params.data.id}
            viewPath={`${props.viewPath}/TreatmentFieldCell(${params.data.id})`}
          />
        ),
      })),
      {
        field: 'actions',
        headerName: 'Actions',
        initialWidth: 100,
        cellRenderer: params => (
          <ActionsCell
            {...params}
            key={params.data.id}
            viewPath={`${props.viewPath}/ActionsCell(${params.data.id})`}
          />
        ),
      },
    ],
    [compact_view_notes, props.viewPath, treatment_fields]
  )
  let isFullWidthRow = useCallback(
    params =>
      ['note', 'order', 'custom_resource'].includes(params.rowNode.data.type),
    []
  )
  let fullWidthCellRenderer = useCallback(
    params => {
      switch (params.data.type) {
        case 'note':
          return (
            <TreatmentNoteRow
              {...params}
              key={params.data.id}
              viewPath={`${props.viewPath}/TreatmentNoteRow(${params.data.id})`}
            />
          )
        case 'order':
          return (
            <OrderRow
              {...params}
              key={params.data.id}
              viewPath={`${props.viewPath}/OrderRow(${params.data.id})`}
            />
          )
        case 'custom_resource':
          return (
            <CustomResourceRow
              {...params}
              key={params.data.id}
              viewPath={`${props.viewPath}/CustomResourceRow(${params.data.id})`}
            />
          )
        default:
          return null
      }
    },
    [props.viewPath]
  )

  function maybeStripHTML(value) {
    return hasHTML(value) ? stripHtml(value) : value
  }

  let processCellForClipboard = useCallback(
    params => {
      if (params.column.getColId() === 'notes') {
        return appointment_notes[params.node.data?.appointment.id]
          ?.map(
            note =>
              `[${note?.author_user?.person.first_name} ${
                note?.author_user?.person.last_name
              }] ${maybeStripHTML(note.note)}`
          )
          .join(`\n`)
      }

      if (params.column.getColId() === 'appointment_summary') {
        return maybeStripHTML(treatment_notes[params.node.data.id].note)
      }
      return params.value
    },
    [appointment_notes, treatment_notes]
  )

  return (
    <View
      {...props}
      columnDefs={columnDefs}
      rowData={pastAppointments}
      height={height}
      processCellForClipboard={processCellForClipboard}
      isFullWidthRow={isFullWidthRow}
      fullWidthCellRenderer={fullWidthCellRenderer}
      shortContextMenuColumnWhitelist={['notes']}
      shortMenuItems={['copy', 'separator', 'autoSizeAll']}
      // this can be used to provide something other than the shortMenuItems for the columns not specified in shortContextMenuColumns
      // for example
      // shortContextMenuAlternative={['autoSizeAll']}

      // this suppresses the browser right click
      gridOptions={{
        preventDefaultOnContextMenu: true,
      }}
    />
  )
}
