import { useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let data = useDataValue({
    viewPath: props.viewPath,
    context: 'launcher',
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    switch (data.resolver.type) {
      case 'url': {
        window.open(data.resolver.data)
        setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
        break
      }

      case 'qr': {
        setFlowTo(normalizePath(props.viewPath, 'ActionQr/Content'))
        break
      }

      case 'http': {
        alert(`TODO: implement http resolver`)
        break
      }

      default: {
        throw new Error(`Unknown resolver type "${data.resolver.type}"`)
      }
    }
  }
}
