// this is an autogenerated file from DesignSystem/PatientOverview/Content/OtherRelatives/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PatientOverview_Content_OtherRelatives($person_id: uuid!) {
  vaxiom_relationships(
    where: {
      to_person_record: { _id: { _eq: $person_id } }
      role: { _neq: "RESPONSIBLE" }
    }
  ) {
    id
    type
    role
    from_person_record {
      id
      first_name
      middle_name
      last_name
    }
  }
}

`