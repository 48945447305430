import { MONTHS } from 'Data/constants.js'
import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let sidebar_date = useDataValue({
    context: 'sidebar',
    viewPath: props.viewPath,
    path: 'date',
  })

  return useMemo(() => {
    return {
      months: MONTHS,
      date: sidebar_date,
    }
  }, [sidebar_date])
}
